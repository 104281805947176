import axios from "axios";
import moment from "moment";
import config from "@/config";

const CACHE_LIFETIME = 1;

const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

const DATE_KEY = "items-date";
const MARKET_DATE_KEY = "market-items-date";
const ITEMS_KEY = "items";

const normalizeItem = data => {
  const [id, name, entity, description, qualityId, type, price, colorId, rarity, count] = data;

  return {
    id,
    name,
    entity,
    description,
    qualityId,
    type,
    colorId,
    rarity,
    price,
    count,
  };
};

const getNextUpdateDate = () => {
  return localStorage.getItem(DATE_KEY) || moment();
};

const setNextUpdateDate = () => {
  return localStorage.setItem(
    DATE_KEY,
    moment()
      .add(CACHE_LIFETIME, "minutes")
      .format(DATE_FORMAT),
  );
};

const load = () => {
  return axios.get(`${config.itemsUrl}?v=${new Date().getTime()}`).then(res => res.data);
};

const getMarketItems = async () => {
  if (moment().isBefore(getNextUpdateDate())) {
    try {
      const result = JSON.parse(localStorage.getItem(ITEMS_KEY));
      if (result instanceof Array) {
        return result;
      }
    } catch (e) {
      console.log(e);
    }
  }

  try {
    const data = await load();
    setNextUpdateDate();

    localStorage.setItem(MARKET_DATE_KEY, data.date);

    const items = data.data.map(normalizeItem).sort((a, b) => b.price - a.price);

    localStorage.setItem(ITEMS_KEY, JSON.stringify(items));

    return items;
  } catch (e) {
    console.log(e);
    return [];
  }
};

export default getMarketItems;
