import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import useMarketItems from "@/libs/market/useMarketItems";
import WithdrawSkinItems from "@/components/withdraw/WithdrawSkinItems";
import WithdrawSkinsFilterItems from "@/components/withdraw/WithdrawSkinsFilterItems";
import itemType from "@/components/withdraw/WithdrawSkinItems/itemType";

const PAGE_SIZE = 8;

const WithdrawSkinsSelectItem = ({ selectedItem, onSelectItem }) => {
  const user = useSelector(({ auth }) => auth.user);

  const availablePrice = user.balance;

  const [name, setName] = useState("");
  const [price, setPrice] = useState(String(availablePrice));

  const { setFilter, items, hasMore, loadMore } = useMarketItems({
    pageSize: PAGE_SIZE,
  });

  useEffect(() => {
    setFilter({
      count: 1,
      maxPrice: Number(price),
      name,
    });
  }, [price, name]);

  return (
    <>
      <WithdrawSkinsFilterItems
        name={name}
        onChangeName={setName}
        price={price}
        onChangePrice={setPrice}
      />
      <WithdrawSkinItems
        items={items}
        hasMore={hasMore}
        loadMore={loadMore}
        selectedItem={selectedItem}
        onSelectItem={onSelectItem}
      />
    </>
  );
};

WithdrawSkinsSelectItem.propTypes = {
  selectedItem: itemType,
  onSelectItem: PropTypes.func.isRequired,
};

WithdrawSkinsSelectItem.defaultProps = {
  selectedItem: null,
};

export default WithdrawSkinsSelectItem;
