import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { setOpenCashier } from "@/actions/mainAction";
import TabModal from "@/components/TabModal";
import DepositMoneyContainer from "@/containers/deposits/DepositContainer";
import WithdrawContainer from "@/containers/withdraw/WithdrawContainer";
import TipContainer from "@/containers/TipContainer";

const CashierContainer = () => {
  const user = useSelector(({ auth }) => auth.user);
  const openCashier = useSelector(({ main }) => main.openCashier);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [modalRef, setModalRef] = useState();

  useEffect(() => {
    if (modalRef && modalRef.current && openCashier) {
      modalRef.current.open();
      dispatch(setOpenCashier(false));
    }
  }, [openCashier, modalRef]);

  const links = [
    {
      id: "deposit",
      icon: (
        <svg aria-hidden="true" className="w-20 h-20">
          <use href="/svg/svg.svg#dollar" />
        </svg>
      ),
      component: <DepositMoneyContainer />,
    },
    {
      id: "withdraw",
      icon: (
        <svg aria-hidden="true" className="w-22 h-22 -ml-1">
          <use href="/svg/svg.svg#money-back-2" />
        </svg>
      ),
      component: <WithdrawContainer />,
    },
    {
      id: "tip",
      icon: (
        <svg aria-hidden="true" className="w-22 h-22 -ml-1">
          <use href="/svg/svg.svg#money-back-2" />
        </svg>
      ),
      component: <TipContainer />,
    },
  ];

  if (!user) {
    return <></>;
  }

  return (
    <TabModal onSetRef={setModalRef} links={links} title={t("cashier.title")} prefix="cashier">
      <div className="hidden sm:flex items-center justify-between text-right px-30">
        <svg aria-hidden="true" className="fill-current text-green w-24 h-24 mr-10">
          <use href="/svg/svg.svg#coins" />
        </svg>
        <div>
          <div className="font-medium text-black uppercase">{t("cashier.balance")}</div>
          <div className="font-medium text-green text-lg">$ {Number(user.balance).toFixed(2)}</div>
        </div>
      </div>
    </TabModal>
  );
};

export default CashierContainer;
