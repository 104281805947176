import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSubscribe } from "@/libs/centrifuge";
import { newRound, removeRound, newBet } from "@/actions/coinflipAction";

const CoinflipSubscriberContainer = () => {
  const subscribe = useSubscribe();
  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = subscribe("coinflip", ({ type, ...payload }) => {
      switch (type) {
        case "round": {
          dispatch(newRound(payload.round));
          break;
        }

        case "remove": {
          dispatch(removeRound(payload.round));
          break;
        }

        case "newBet": {
          dispatch(newBet(payload));
          break;
        }

        default:
          break;
      }
    });

    return () => unsubscribe();
  }, []);

  return <></>;
};

export default CoinflipSubscriberContainer;
