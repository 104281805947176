import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  COINFLIP_JOINED_STATUS,
  COINFLIP_ROLLING_STATUS,
  COINFLIP_FINISH_STATUS,
} from "@/constants/coinflipStatuses";
import roundType from "@/components/games/coinflip/roundType";
import Countdown from "@/components/Countdown";

const CoinflipRound = ({ round, onClickBack }) => {
  const { id, hash, deposit, player1, player2, status, winner, waitingTime } = round;

  const { t } = useTranslation();

  return (
    <>
      <div className="flex items-center justify-between lg:absolute lg:top-0 lg:inset-x-0 py-20 md:py-30 px-10 md:px-30">
        <button type="button" className="btn btn--sm btn--black btn--rounded pr-20" onClick={onClickBack}>
          <svg aria-hidden="true" className="fill-current w-8 h-10 mr-12">
            <use href="/svg/svg.svg#back" />
          </svg>
          {t("coinflip.back")}
        </button>
        <div className="text-right">
          <div className="text-gray items-center inline-flex">
            <svg aria-hidden="true" className="fill-current w-16 h-16 mr-20">
              <use href="/svg/svg.svg#info" />
            </svg>
            {t("coinflip.roundDesc")}
          </div>
          <div className="text-gray-400 mt-5">$ {deposit}</div>
        </div>
      </div>

      <div className="lg:h-650 py-20 md:py-30 px-10 md:px-30 text-center lg:pt-100">
        {status === COINFLIP_JOINED_STATUS && (
          <>
            <div className="text-4xl sm:text-5xl font-medium">{t("coinflip.start.title")}</div>
            <div className="text-2xl sm:text-3xl text-gray-600 mt-6 sm:mt-8">{t("coinflip.start.sub")}</div>

            <Countdown date={waitingTime} />
          </>
        )}

        {status === COINFLIP_ROLLING_STATUS && (
          <>
            <div className="text-4xl sm:text-5xl font-medium">{t("coinflip.choose")}</div>
            <div className="text-2xl sm:text-3xl text-gray-600 mt-6 sm:mt-8">
              {t("coinflip.winner")}
            </div>

            <div className="w-300 mx-auto mt-10 text-left">
              <div className="text-2xl text-white mb-10 h-18" />
              <div className="h-2 bg-primary-100 bg-opacity-10 w-full" />
            </div>
          </>
        )}

        {status === COINFLIP_FINISH_STATUS && (
          <>
            <div className="text-4xl sm:text-5xl font-medium">
              {t("coinflip.winner")}
            </div>
            <div className="text-2xl sm:text-3xl text-green mt-6 sm:mt-8">
              {winner === 2 ? player2.name || t("hidden") : player1.name || t("hidden")}
            </div>

            <div className="w-300 mx-auto mt-10 text-left">
              <div className="text-2xl text-white mb-10 h-18" />
              <div className="h-2 bg-primary-100 bg-opacity-10 w-full">
                <div className="h-full relative mx-auto bg-green" style={{ width: "50%" }} />
              </div>
            </div>
          </>
        )}

        <div className="bg-primary-100 bg-opacity-5 px-20 py-23 rounded flex flex-col sm:flex-row justify-between mt-50 lg:mt-70 relative">
          <div className="flex items-center text-left">
            <div className="logo-circle">
              <svg aria-hidden="true">
                <use href="/svg/svg.svg#logo" />
              </svg>
              <svg aria-hidden="true">
                <use href="/svg/svg.svg#logo" />
              </svg>
            </div>

            <div className="px-20 mr-auto">
              <div className="text-lg mb-5">{player1.name}</div>
            </div>
          </div>

          <div className="sm:absolute-center flex items-center justify-center my-15 sm:my-0">
            <div className="mr-12 text-center">
              <div className="text-green mb-5">{deposit}</div>
              <div className="currency-symbol bg-green">$</div>
            </div>
            <div className="p-10 md:p-15 bg-black rounded-full">
              {status === COINFLIP_ROLLING_STATUS && (
                <div className="coin animation900 w-100 h-100">
                  <div className="logo-circle front w-100 h-100">
                    <svg aria-hidden="true">
                      <use href="/svg/svg.svg#logo" />
                    </svg>
                    <svg aria-hidden="true">
                      <use href="/svg/svg.svg#logo" />
                    </svg>
                  </div>
                  <div className="logo-circle back bg-red w-100 h-100">
                    <svg aria-hidden="true">
                      <use href="/svg/svg.svg#logo" />
                    </svg>
                    <svg aria-hidden="true">
                      <use href="/svg/svg.svg#logo" />
                    </svg>
                  </div>
                </div>
              )}
              {[COINFLIP_JOINED_STATUS, COINFLIP_FINISH_STATUS].includes(status) && (
                <div className={`logo-circle w-100 h-100 ${winner === 2 ? "bg-red" : ""}`}>
                  <svg aria-hidden="true">
                    <use href="/svg/svg.svg#logo" />
                  </svg>
                  <svg aria-hidden="true">
                    <use href="/svg/svg.svg#logo" />
                  </svg>
                </div>
              )}
            </div>
            <div className="ml-12 text-center">
              <div className="text-green mb-5">{deposit}</div>
              <div className="currency-symbol bg-green">$</div>
            </div>
          </div>

          <div className="flex items-center flex-row-reverse text-right self-end lg:self-center">
            <div className="logo-circle bg-red">
              <svg aria-hidden="true">
                <use href="/svg/svg.svg#logo" />
              </svg>
              <svg aria-hidden="true">
                <use href="/svg/svg.svg#logo" />
              </svg>
            </div>
            <div className="px-20 mr-auto">
              <div className="text-lg mb-5">{player2 && player2.name}</div>
            </div>
          </div>
        </div>

        <div className="mt-30 mb-50 md:mb-80">
          <div className="flex justify-between px-10">
            <i className="h-4 w-2 bg-primary-100 bg-opacity-40" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-4 w-2 bg-primary-100 bg-opacity-40" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-4 w-2 bg-primary-100 bg-opacity-40" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-13 w-2 bg-primary-100 bg-opacity-40" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-4 w-2 bg-primary-100 bg-opacity-40" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-4 w-2 bg-primary-100 bg-opacity-40" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-8 w-2 bg-primary-100 bg-opacity-10" />
            <i className="h-4 w-2 bg-primary-100 bg-opacity-40" />
          </div>
        </div>

        <div className="w-230 mb-30 bg-primary-100 bg-opacity-10 h-2 mx-auto" />
        <div className="text-xl font-bold mb-5">COINFLIP #{id}</div>
        <div className="text-gray-600 mb-20">Hash: {hash}</div>
      </div>
    </>
  );
};

CoinflipRound.propTypes = {
  round: roundType.isRequired,
  onClickBack: PropTypes.func.isRequired,
};

export default CoinflipRound;
