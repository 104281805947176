import React, { useState } from "react";
import PropTypes from "prop-types";

const MinesBombsInput = ({ value, onChange, disabled }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [bombs, setBombs] = useState([1, 3, 5, 10]);

  return (
    <div className="flex items-center justify-between">
      <div className="inline-grid grid-cols-4 gap-8 w-250 max-w-full">
        {bombs
          .map((bomb, index) => ({ bomb, key: index }))
          .map(({ bomb, key }, index) => {
            return isEdit ? (
              <input
                type="number"
                key={key}
                className="btn btn--md btn--light-black w-full text-center px-5 focus:text-white"
                value={bomb}
                onChange={e => {
                  let count = e.target.value > 20 ? 20 : parseInt(e.target.value, 10);
                  if (count < 1) {
                    count = 1;
                  }
                  bombs[index] = count;
                  setBombs([...bombs]);
                }}
              />
            ) : (
              <button
                type="button"
                key={key}
                className={`btn btn--md btn--light-black w-full text-center px-5 focus:text-white ${
                  value === bomb ? "text-white" : ""
                }`}
                onClick={() => {
                  if (disabled) {
                    return;
                  }
                  onChange(bomb);
                }}
              >
                {bomb}
              </button>
            );
          })}
      </div>
      <button
        type="button"
        className={`btn btn--md btn--fab flex-shrink-0 ml-18 ${
          isEdit ? "btn--green" : "btn--light-black"
        }`}
        onClick={() => {
          if (disabled) {
            return;
          }
          setIsEdit(!isEdit);
        }}
      >
        <svg aria-hidden="true" className="fill-current w-16 h-16">
          <use href={`/svg/svg.svg#${isEdit ? "basic-tick" : "gear"}`} />
        </svg>
      </button>
    </div>
  );
};

MinesBombsInput.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default MinesBombsInput;
