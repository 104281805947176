import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const WithdrawSkinsFilterItems = ({ name, onChangeName, price, onChangePrice }) => {
  const { t } = useTranslation();

  return (
    <div className="bg-gray-50 py-6 sm:pl-10 pr-20 h-55 rounded mb-15 flex items-center justify-between">
      <label className="relative h-full w-full">
        <span className="sr-only">{t("withdraw.filter.name")}</span>
        <input
          value={name}
          onChange={e => onChangeName(e.target.value)}
          onBlur={e => onChangeName(e.target.value)}
          type="text"
          placeholder={t("withdraw.filter.namePlaceholder")}
          className="text-2xl lg:text-base appearance-none bg-transparent border-none focus:outline-none h-full w-full pl-15 pr-20 sm:px-25 placeholder-gray-500 text-black-900"
        />
        <svg
          aria-hidden="true"
          className="fill-current text-gray-600 w-20 h-20 absolute top-1/2 right-0 transform -translate-y-1/2"
        >
          <use href="/svg/svg.svg#magnifier" />
        </svg>
      </label>
      <div className="w-2 h-10 bg-gray-150 bg-opacity-75 ml-10 sm:ml-15 lg:ml-22" />
      <label className="relative h-full w-110 flex-shrink-0">
        <span className="sr-only">{t("withdraw.filter.price")}</span>
        <input
          value={price}
          onChange={e => onChangePrice(e.target.value)}
          onBlur={e => onChangePrice(e.target.value)}
          type="number"
          placeholder={t("withdraw.filter.pricePlaceholder")}
          className="text-2xl lg:text-base appearance-none bg-transparent border-none focus:outline-none h-full w-full pl-12 pr-17 sm:px-15 lg:px-25 placeholder-gray-500 text-black-900"
        />
        <div className="text-lg text-gray-600 absolute top-1/2 right-0 transform -translate-y-1/2 mt-1">
          $
        </div>
      </label>
    </div>
  );
};

WithdrawSkinsFilterItems.propTypes = {
  name: PropTypes.string,
  onChangeName: PropTypes.func.isRequired,
  price: PropTypes.string,
  onChangePrice: PropTypes.func.isRequired,
};

WithdrawSkinsFilterItems.defaultProps = {
  name: "",
  price: "",
}

export default WithdrawSkinsFilterItems;
