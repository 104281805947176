import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import WalletPrefix from "@/components/Wallet/WalletPrefix";
import { WALLET_USDT_TYPE } from "@/constants/walletTypes";
import getToFixed from "@/components/Wallet/getToFixed";

const InputDeposit = ({ walletType, label, value, onChange, disabled }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="text-lg">{label || t("inputDeposit")}</div>
      <label htmlFor="game-deposit" className="block relative mb-20">
        <span className="absolute left-0 top-1/2 transform -translate-y-1/2 -mt-1 text-gray-600">
          <WalletPrefix type={walletType} />
        </span>
        <input
          id="game-deposit"
          type="text"
          className={`text-field pl-${walletType === WALLET_USDT_TYPE ? "20" : "30"}`}
          placeholder={Number(0).toFixed(getToFixed(walletType))}
          value={value}
          onChange={e => {
            if (!disabled) {
              onChange(e.target.value);
            }
          }}
          onBlur={e => {
            if (!e.target.value || e.target.value < 0) {
              onChange("");
            } else {
              onChange(Number(e.target.value).toFixed(getToFixed(walletType)));
            }
          }}
        />
      </label>
    </>
  );
};

InputDeposit.propTypes = {
  walletType: PropTypes.number.isRequired,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

InputDeposit.defaultProps = {
  label: null,
  value: 0.0,
  disabled: false,
};

export default InputDeposit;
