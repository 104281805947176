import React from "react";
import useScrollTop from "@/helpers/useScrollTop";

const ResponsiblePage = () => {

  useScrollTop();

  return (
    <div className="bg-black rounded">
      <div className="px-15 sm:px-30 py-20 border-b-4 border-black-800">Responsible Gaming</div>
      <div className="px-15 sm:px-30 py-20 prose text-gray-700">
        <p>Gaming is a fun and exciting entertainment. Gambling is an attractive option if you want to try your luck. Most casino visitors enjoy this kind of entertainment without any problems, but there are a small number of people who lose control over their gambling activity. Play responsibly!</p>
        <p>Compulsive gambler</p>
        <p>Signs indicating a disorder include the person&rsquo;s inability to stop gambling, borrowing money for gambling, changes in the character of the player, risking their employment or family for the sake of gambling.</p>
        <p>If you are not sure, whether you are addicted to gambling, we recommend you pass an anonymous test via the following link: https://www.begambleaware.org/gambling-problems/do-i-have-a-gambling-problem/</p>
        <p>Limits</p>
        <p>Loss limit allows a player to set the maximum loss limit for a selected period. There is always a possibility to change the loss limit, but it takes effect accordingly:</p>
        <p>Changing the limit to more restrictive takes effect immediately</p>
        <p>Changing the limit to less restrictive takes effect 48 hours after the change.</p>
        <p>Please write to support@midasly.com in order to establish a limit.</p>
        <p>Self-exclusion</p>
        <p>You can also inform us about your decision to stop gambling by contacting support@midasly.com.&nbsp;</p>
        <p>We will take measures to limit access to your account and exclude you from receiving promotional offers.&nbsp;</p>
        <p>Help from external sources&nbsp;</p>
        <p>The following organizations offer consultation and support in regard to gambling addiction:&nbsp;</p>
        <p>Gambling Therapy</p>
        <p>Gamblers Anonymous</p>
        <p>GamCare</p>
        <p>Underage gambling&nbsp;</p>
        <p><br/></p>
        <p>We do not tolerate underage gambling.</p>
        <p>However, due to the nature of the internet, there is still a chance that individuals under the legal age may register and play.&nbsp;</p>
        <p>We strongly advise parents to protect their children from accessing online gaming websites by using special software:</p>
        <p>Net Nanny</p>
        <p>CyberPatrol</p>
        <p>GamBlock&reg;</p>
        <p>Solid Oak Software</p>
      </div>
    </div>
  );
};

export default ResponsiblePage;