import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import GameHeaderContainer from "@/containers/games/GameHeaderContainer";
import GameFooterContainer from "@/containers/games/GameFooterContainer";
import CrashSubscriberContainer from "@/containers/games/crash/CrashSubscriberContainer";
import CrashBetsSubscriberContainer from "@/containers/games/crash/CrashBetsSubscriberContainer";
import CrashChartContainer from "@/containers/games/crash/CrashChartContainer";
import CrashSidebarContainer from "@/containers/games/crash/CrashSidebarContainer";
import CrashHistoryContainer from "@/containers/games/crash/CrashHistoryContainer";
import CrashStateContainer from "@/containers/games/crash/CrashStateContainer";

const CrashMainContainer = () => {
  const { t } = useTranslation();
  const ref = useRef();

  return (
    <>
      <GameHeaderContainer title={t("crash.title")} />
      <div className="game-wrapper game-wrapper--has-sidebar" ref={ref}>
        <CrashChartContainer mainRef={ref} />
        <CrashSidebarContainer />
      </div>
      <GameFooterContainer className="game-footer__crash">
        <CrashHistoryContainer />
      </GameFooterContainer>
      <CrashStateContainer />
      <CrashSubscriberContainer />
      <CrashBetsSubscriberContainer />
    </>
  );
};

export default CrashMainContainer;
