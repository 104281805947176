import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import PaymentMethods from "@/components/deposit/PaymentMethods";
import Field from "@/components/ui/Field";

const Deposit = ({ paymentMethods, onSubmit }) => {
  const { t } = useTranslation();
  const [paymentMethod, setPaymentMethod] = useState(paymentMethods[0]);
  const [amount, setAmount] = useState();

  const submit = () => {
    if (paymentMethod && amount) {
      onSubmit({
        paymentMethod,
        amount: Number(amount),
      });
    }
  };

  return (
    <>
      <PaymentMethods
        paymentMethods={paymentMethods}
        onSelect={setPaymentMethod}
        activePaymentMethod={paymentMethod}
      />
      <div className="space-y-20 sm:space-y-35 mt-30">
        <Field
          name="deposit-amount"
          type="number"
          titleClassName="text-black-900"
          inputClassName="text-field--light"
          placeholder="$ 0.00"
          label={t("cashier.deposit.enterAmount")}
          value={String(amount)}
          handleChange={e => setAmount(e.target.value)}
          handleBlur={e => setAmount(e.target.value)}
          errorMessageDisabled
        />
      </div>
      <button
        type="button"
        className="btn btn--primary-light btn--bold btn--md btn--rounded mt-10"
        onClick={submit}
      >
        {t("cashier.deposit.button")} <span className="ml-15">+</span>
      </button>
    </>
  );
};

Deposit.propTypes = {
  paymentMethods: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      img: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default Deposit;
