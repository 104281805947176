import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useApi } from "@/api";
import noty from "@/libs/noty";
import CoinflipCreateForm from "@/components/games/coinflip/CoinflipCreateForm";
import CoinflipLuckyWins from "@/components/games/coinflip/CoinflipLuckyWins";
import { WALLET_USDT_TYPE } from "@/constants/walletTypes";
import { setOpenAuth } from "@/actions/mainAction";

const CoinflipSidebarContainer = () => {
  const { t } = useTranslation();
  const [pending, setPending] = useState(false);
  const { coinflipCreate } = useApi();
  const dispatch = useDispatch();

  const user = useSelector(({ auth }) => auth.user);
  const currencies = useSelector(({ main }) => main.currencies);
  const inMoney = useSelector(({ auth }) => auth.walletSettings.inMoney);

  const bets = useSelector(({ coinflip }) => coinflip.bets);

  const walletType = user && user.activeWallet ? user.activeWallet.type : WALLET_USDT_TYPE;
  const rate = currencies[walletType];

  const create = amount => {
    const deposit = inMoney ? amount / rate : amount;

    if (!user) {
      dispatch(setOpenAuth(true));
      return;
    }

    if (deposit > user.activeWallet.balance) {
      noty.error(t("notEnoughBalance"));
      return;
    }
    if (pending) {
      return;
    }

    setPending(true);

    coinflipCreate({ deposit })
      .then(() => {
        noty.success(t("coinflip.createSuccess"));
      })
      .catch(() => {
        noty.error(t("coinflip.createFail"));
      })
      .finally(() => {
        setPending(false);
      });
  };

  return (
    <div className="game-sidebar-inner">
      <CoinflipCreateForm onSubmit={create} walletType={inMoney ? WALLET_USDT_TYPE : walletType} />
      <CoinflipLuckyWins bets={bets} />
    </div>
  );
};

export default CoinflipSidebarContainer;
