import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import {
  setRound,
  setRoundDemo,
  setRoundDifficulty,
  setRoundBombCells,
  setRoundStatus,
  setRoundGameboardDeposit,
} from "@/actions/towersAction";
import { setOpenAuth } from "@/actions/mainAction";
import { useApi } from "@/api";
import useSound from "@/helpers/useSound";
import noty from "@/libs/noty";
import {
  TOWERS_EASY_DIFFICULTY,
  TOWERS_MEDIUM_DIFFICULTY,
  TOWERS_HARD_DIFFICULTY,
} from "@/constants/towersDifficulty";
import { TOWERS_PENDING_STATUS, TOWERS_RESOLVE_STATUS } from "@/constants/towersStatus";
import { WALLET_USDT_TYPE } from "@/constants/walletTypes";
import TowersControl from "@/components/games/towers/TowersControl";
import createDemoCells from "./createDemoCells";

const TowersControlContainer = () => {
  const { t } = useTranslation();
  const { towersCreate, towersCashOut } = useApi();
  const user = useSelector(({ auth }) => auth.user);
  const currencies = useSelector(({ main }) => main.currencies);
  const inMoney = useSelector(({ auth }) => auth.walletSettings.inMoney);
  const id = useSelector(({ towers }) => towers.round.id);
  const status = useSelector(({ towers }) => towers.round.status);
  const deposit = useSelector(({ towers }) => towers.round.deposit);
  const difficulty = useSelector(({ towers }) => towers.round.difficulty);
  const withdraw = useSelector(({ towers }) => towers.round.withdraw);
  const demo = useSelector(({ towers }) => towers.demo);
  const dispatch = useDispatch();
  const sound = useSound();

  const walletType = user && user.activeWallet ? user.activeWallet.type : WALLET_USDT_TYPE;
  const rate = currencies[walletType];

  const createDemo = difficulty => {
    sound.bet();
    const demoCells = createDemoCells(difficulty);
    dispatch(setRoundDemo(demoCells));

    dispatch(
      setRound({
        id: 0,
        deposit: 0,
        gameboardDeposit: 0,
        withdraw: 0,
        steps: [],
        status: TOWERS_PENDING_STATUS,
        walletType: WALLET_USDT_TYPE,
        bombCells: [],
        difficulty,
      }),
    );
  };

  const create = ({ level, ...data }) => {
    if (!user) {
      dispatch(setOpenAuth(true));
      return;
    }

    let { deposit } = data;

    if (Number(deposit) === 0) {
      createDemo(level);
      return;
    }

    if (inMoney) {
      deposit /= rate;
    }

    if (user.activeWallet.balance < deposit) {
      noty.error(t("notEnoughBalance"));
      return;
    }

    dispatch(setRoundDemo(null));
    towersCreate({ difficulty: level, deposit })
      .then(newRound => {
        dispatch(setRound(newRound));
      })
      .catch(() => {
        noty.error(t("towers.create.error"));
      });
  };

  const cashOut = () => {
    if (demo) {
      sound.minesWin();
      dispatch(setRoundStatus(TOWERS_RESOLVE_STATUS));
      dispatch(setRoundBombCells(demo));
      return;
    }

    towersCashOut()
      .then(updatedRound => {
        sound.minesWin();
        dispatch(setRound(updatedRound));
      })
      .catch(() => {
        noty.error(t("towers.cashOut.error"));
      });
  };

  return (
    <TowersControl
      isStarting={typeof id === "number" && status === TOWERS_PENDING_STATUS}
      levels={[TOWERS_EASY_DIFFICULTY, TOWERS_MEDIUM_DIFFICULTY, TOWERS_HARD_DIFFICULTY]}
      onCreate={create}
      onCashOut={cashOut}
      startedDeposit={inMoney ? deposit * rate : deposit}
      onChangeDeposit={newDeposit => {
        dispatch(setRoundGameboardDeposit(Number(inMoney ? newDeposit / rate : newDeposit)));
      }}
      withdraw={Number(inMoney ? withdraw * rate : withdraw)}
      level={difficulty || 1}
      onChangeLevel={newDifficulty => dispatch(setRoundDifficulty(newDifficulty))}
      walletType={inMoney ? WALLET_USDT_TYPE : walletType}
    />
  );
};

export default TowersControlContainer;
