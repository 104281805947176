import { useEffect } from "react";

const useMenuAnimation = (menuRef, activeLinkRef, trigger) => {
  const render = () => {
    const menu = menuRef.current;

    const menuRect = menu.getBoundingClientRect();
    const linkRect = activeLinkRef.current.getBoundingClientRect();

    menu.style.setProperty("--marker-offset-top", `${linkRect.top - menuRect.top}px`);
    menu.style.setProperty("--marker-width", `${linkRect.width}px`);
    menu.style.setProperty("--marker-height", `${linkRect.height}px`);
    menu.style.setProperty("--marker-offset-left", `${linkRect.left - menuRect.left}px`);
  };

  useEffect(() => {
    if (menuRef && menuRef.current && activeLinkRef && activeLinkRef.current) {
      render();
    }
  }, [menuRef, activeLinkRef, trigger]);
};

export default useMenuAnimation;
