import React from "react";

const WithdrawMoneyContainer = () => {
  return (
    <div>
      Money
    </div>
  );
};

export default WithdrawMoneyContainer;