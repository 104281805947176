import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import getToFixed from "@/components/Wallet/getToFixed";
import { WALLET_USDT_TYPE } from "@/constants/walletTypes";
import WalletPrefix from "@/components/Wallet/WalletPrefix";

const Bets = ({ height, bets }) => {
  const { t } = useTranslation();

  const inMoney = useSelector(({ auth }) => auth.walletSettings.inMoney);
  const currencies = useSelector(({ main }) => main.currencies);

  return (
    <ul
      className="overflow-auto focus-outline-inset disable-scrollbars lg:max-h-full"
      style={{ height }}
    >
      {bets.map(({ id, deposit, withdraw, userName, walletType }) => {
        const isWin = withdraw > 0;
        const prize = isWin ? withdraw : deposit;

        return (
          <li
            key={id}
            className="flex items-center justify-between px-20 py-5 odd:bg-black-600 relative z-1"
          >
            <div
              className={`absolute inset-y-0 right-0 w-150 bg-gradient-to-l from-${
                isWin ? "green" : "red"
              } opacity-15 -z-1`}
            />
            <div>{userName || t("hidden")}</div>
            <div className="flex items-center">
              <span className={isWin ? "text-green-200" : "text-red"}>
                {isWin ? "+" : "-"}
                {(inMoney ? prize * currencies[walletType] : prize).toFixed(
                  getToFixed(walletType, inMoney),
                )}
              </span>
              <div className={`currency-symbol ml-7 ${isWin ? "bg-green" : "bg-red"}`}>
                <WalletPrefix type={inMoney ? WALLET_USDT_TYPE : walletType} />
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

Bets.propTypes = {
  bets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      deposit: PropTypes.number.isRequired,
      withdraw: PropTypes.number.isRequired,
      walletType: PropTypes.number.isRequired,
      userName: PropTypes.string,
    }),
  ).isRequired,
  height: PropTypes.string,
};

Bets.defaultProps = {
  height: "24rem",
};

export default Bets;
