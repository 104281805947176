import React from "react";
import Fair from "@/components/Fair";
import useScrollTop from "@/helpers/useScrollTop";

const FairContainer = () => {

  useScrollTop();

  return <Fair items={["overview", "mines"]} />;
};

export default FairContainer;