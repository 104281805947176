import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { setLockManualBet } from "@/actions/crashAction";
import { useApi } from "@/api";
import { CRASH_SHIPPED_STATUS, CRASH_WAITING_STATUS } from "@/constants/crashStatuses";
import { BET_WITHDRAW_STATUS } from "@/constants/crashBetStatuses";
import CrashAutoBet from "@/components/games/crash/CrashAutoBet";
import noty from "@/libs/noty";
import { WALLET_USDT_TYPE } from "@/constants/walletTypes";

const CrashAutoBetContainer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user);
  const status = useSelector(({ crash }) => crash.status);
  const bet = useSelector(({ crash }) => crash.bet);
  const currencies = useSelector(({ main }) => main.currencies);
  const inMoney = useSelector(({ auth }) => auth.walletSettings.inMoney);

  const { crashMake } = useApi();

  const [isActive, setIsActive] = useState(false);
  const [data, setData] = useState({});
  const [currentDeposit, setCurrentDeposit] = useState(0);
  const [currentCount, setCurrentCount] = useState(0);
  const [previewBet, setPreviewBet] = useState(null);
  const [profitDeposit, setProfitDeposit] = useState(0);
  const [loseDeposit, setLoseDeposit] = useState(0);

  const walletType = user && user.activeWallet ? user.activeWallet.type : WALLET_USDT_TYPE;

  const start = newData => {
    let { deposit } = newData;

    if (inMoney) {
      deposit /= currencies[walletType];
    }

    setIsActive(true);
    setCurrentCount(newData.count);
    setCurrentDeposit(deposit);
    setProfitDeposit(0);
    setLoseDeposit(0);
    setData({
      ...newData,
      deposit,
    });
    dispatch(setLockManualBet(true));
  };
  const stop = () => {
    setIsActive(false);
    dispatch(setLockManualBet(false));
  };

  useEffect(() => {
    if (status === CRASH_WAITING_STATUS && isActive && !bet) {
      if (currentDeposit && data.autoCoefficient) {
        if (currentDeposit > user.activeWallet.balance) {
          noty.error(t("notEnoughBalance"));
          stop();
          return;
        }

        crashMake({ deposit: currentDeposit, autoCoefficient: data.autoCoefficient })
          .then(() => {
            noty.success(t("crash.create.success"));
          })
          .catch(() => {
            stop();
            noty.error(t("crash.create.error"));
          });
      }
    }
  }, [currentDeposit, data, status, isActive, bet, inMoney]);

  useEffect(() => {
    if (status === CRASH_SHIPPED_STATUS && bet) {
      if (previewBet && previewBet.id === bet.id) {
        return;
      }
      setPreviewBet(bet);

      if (currentCount === 1) {
        stop();
        return;
      }

      const isWin = bet.status === BET_WITHDRAW_STATUS;
      let newDeposit = currentDeposit;

      if (isWin) {
        setProfitDeposit(profitDeposit + bet.withdraw);
        if (data.onWinIncrease) {
          if (data.onWinPercent) {
            const increase = (Number(currentDeposit) * data.onWinPercent) / 100;
            newDeposit = Number(newDeposit) + increase;
          }
        } else {
          newDeposit = data.deposit;
        }
      } else {
        setLoseDeposit(loseDeposit + bet.deposit);
        if (data.onLoseIncrease) {
          if (data.onLosePercent) {
            const increase = (Number(currentDeposit) * data.onLosePercent) / 100;
            newDeposit = Number(newDeposit) + increase;
          }
        } else {
          newDeposit = data.deposit;
        }
      }

      setCurrentDeposit(newDeposit);

      if (currentCount > 0) {
        setCurrentCount(currentCount - 1);
      }
    }
  }, [status, data, bet, previewBet, profitDeposit, loseDeposit]);

  useEffect(() => {
    if (data.stopOnProfit && profitDeposit >= data.stopOnProfit) {
      stop();
    }
    if (data.stopOnLose && loseDeposit >= data.stopOnLose) {
      stop();
    }
  }, [profitDeposit, loseDeposit, data]);

  let balance = user ? user.activeWallet.balance : 0;

  if (inMoney) {
    balance *= currencies[walletType];
  }

  return (
    <CrashAutoBet
      balance={balance}
      isStarted={isActive}
      defaultCount={currentCount}
      defaultDeposit={inMoney ? currentDeposit * currencies[walletType] : currentDeposit}
      disabled={false}
      onSubmit={start}
      onStop={stop}
      walletType={inMoney ? WALLET_USDT_TYPE : walletType}
      inMoney={inMoney}
    />
  );
};

export default CrashAutoBetContainer;
