import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { setFullScreen, setFullSize, setIsGamePage } from "@/actions/mainAction";
import GameFooter from "@/components/games/GameFooter";
import Tooltip from "@/components/ui/Tooltip";

const GameFooterContainer = ({ children, maxProfit, className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fullScreen = useSelector(({ main }) => main.fullScreen);
  const fullSize = useSelector(({ main }) => main.fullSize);

  useEffect(() => {
    dispatch(setIsGamePage(true));
    return () => {
      dispatch(setIsGamePage(false));
    };
  }, []);

  const toggleFullscreen = () => {
    const element = document.querySelector("#game-wrapper");

    const isFullscreen = document.webkitIsFullScreen || document.mozFullScreen || false;

    element.requestFullScreen =
      element.requestFullScreen ||
      element.webkitRequestFullScreen ||
      element.mozRequestFullScreen ||
      function() {
        return false;
      };
    document.cancelFullScreen =
      document.cancelFullScreen ||
      document.webkitCancelFullScreen ||
      document.mozCancelFullScreen ||
      function() {
        return false;
      };

    if (isFullscreen) {
      document.cancelFullScreen();
    } else {
      element.requestFullScreen();
    }
  };

  return (
    <GameFooter
      className={className}
      onClickFullSize={() => {
        dispatch(setFullSize(!fullSize));
        dispatch(setFullScreen(false));
      }}
      onClickFullScreen={() => {
        dispatch(setFullScreen(!fullScreen));
        dispatch(setFullSize(false));
        toggleFullscreen();
      }}
    >
      {children || <Tooltip title={t("maxProfit")} content={`$${maxProfit}`} />}
    </GameFooter>
  );
};

GameFooterContainer.propTypes = {
  children: PropTypes.node,
  maxProfit: PropTypes.string,
  className: PropTypes.string,
};

GameFooterContainer.defaultProps = {
  children: null,
  maxProfit: "50 000",
  className: "",
};

export default GameFooterContainer;
