import {
  AUTH_SET_TOKEN,
  AUTH_CLEAR,
  SET_USER,
  SET_USER_LANG,
  SET_USER_BALANCE,
  SET_USER_CLIENT_SEED,
  SET_USER_TRADE_URL,
  SET_NOTIFICATIONS,
  NEW_NOTIFICATION,
  UPDATE_USER,
  UPDATE_WALLET,
  SET_WALLET_IN_MONEY,
  SET_WALLET_CLOSE_ZERO,
} from "@/constants";

const initialState = {
  user: null,
  notifications: [],
  token: null,
  walletSettings: {
    inMoney: false,
    closeZero: false,
  },
};

const auth = (state = { ...initialState }, action) => {
  switch (action.type) {
    case AUTH_SET_TOKEN: {
      return {
        ...state,
        token: action.payload,
      };
    }

    case SET_USER: {
      return {
        ...state,
        user: action.payload,
      };
    }

    case UPDATE_USER: {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    }

    case UPDATE_WALLET: {
      if (!state.user) {
        return state;
      }

      console.log("updateWallet", action.payload);

      const { wallets, activeWallet } = state.user;

      const walletIndex = wallets.findIndex(({ id }) => action.payload.id === id);

      if (walletIndex > -1) {
        wallets[walletIndex] = action.payload;
      } else {
        wallets.push(action.payload);
      }

      return {
        ...state,
        user: {
          ...state.user,
          wallets: [...wallets],
          activeWallet:
            activeWallet && activeWallet.id === action.payload.id ? action.payload : activeWallet,
        },
      };
    }

    case AUTH_CLEAR: {
      return {
        ...initialState,
      };
    }

    case SET_USER_LANG: {
      return {
        ...state,
        user: {
          ...state.user,
          lang: action.payload,
        },
      };
    }

    case SET_USER_CLIENT_SEED: {
      return {
        ...state,
        user: {
          ...state.user,
          clientSeed: action.payload,
        },
      };
    }

    case SET_USER_TRADE_URL: {
      return {
        ...state,
        user: {
          ...state.user,
          tradeUrl: action.payload,
        },
      };
    }

    case SET_USER_BALANCE: {
      return {
        ...state,
        user: {
          ...state.user,
          balance: action.payload,
        },
      };
    }

    case SET_NOTIFICATIONS: {
      return {
        ...state,
        notifications: [...action.payload],
      };
    }

    case NEW_NOTIFICATION: {
      return {
        ...state,
        notifications: [action.payload, ...state.notifications],
      };
    }

    case SET_WALLET_IN_MONEY: {
      return {
        ...state,
        walletSettings: {
          ...state.walletSettings,
          inMoney: action.payload,
        },
      };
    }

    case SET_WALLET_CLOSE_ZERO: {
      return {
        ...state,
        walletSettings: {
          ...state.walletSettings,
          closeZero: action.payload,
        },
      };
    }

    default:
      return state;
  }
};

export default auth;
