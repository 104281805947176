import React from "react";
import { useTranslation } from "react-i18next";
import WithdrawCryptoContainer from "@/containers/withdraw/WithdrawCryptoContainer";
import WithdrawMoneyContainer from "@/containers/withdraw/WithdrawMoneyContainer";
import WithdrawSkinsContainer from "@/containers/withdraw/WithdrawSkinsContainer";
import TabPanel from "@/components/TabPanel";

const WithdrawContainer = () => {
  const { t } = useTranslation();

  const items = [
    {
      id: "crypto",
      label: t("withdraw.tab.crypto"),
      component: <WithdrawCryptoContainer />,
    },
    {
      id: "money",
      label: t("withdraw.tab.money"),
      component: <WithdrawMoneyContainer />,
    },
    {
      id: "skins",
      label: t("withdraw.tab.skins"),
      component: <WithdrawSkinsContainer />,
    },
  ];

  return <TabPanel items={items} />;
};

export default WithdrawContainer;
