import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import SecondaryNav from "@/components/SecondaryNav";

const DiceSelectRoom = ({ rooms, activeRoomId, activeDeposit, setActiveRoom }) => {
  const groupByDeposit = _.groupBy(rooms, "deposit");
  const deposits = Object.keys(groupByDeposit).sort((a, b) => {
    return a - b;
  });
  const { t } = useTranslation();

  const activeRooms = groupByDeposit[activeDeposit];

  if (!activeRooms) {
    return <></>;
  }

  return (
    <>
      <div className="text-lg mb-10">{t("dice.rooms")}</div>
      <div className="text-gray-600 text-lg">
        <span>{t("dice.choseRoom")}</span>
      </div>

      <div className="h-2 bg-black-600 mt-16" />
      <SecondaryNav
        items={deposits.map(deposit => ({ id: deposit, label: `$ ${deposit}` }))}
        setActiveItem={({ id }) => setActiveRoom(groupByDeposit[id][0])}
        activeItem={{ id: String(activeDeposit) }}
      />
      <div className="h-2 bg-black-600 mb-16" />

      <div className="space-y-10 mb-20">
        {activeRooms.map((room, index) => {
          const { id, usersCount } = room;
          return (
            <button
              key={id}
              type="button"
              className={`btn btn--light-black hover:bg-black-660 btn--md w-full ${
                activeRoomId === id ? "bg-black-660" : ""
              }`}
              onClick={() => setActiveRoom(room)}
            >
              <span>
                {t("dice.room")} #{index + 1}
              </span>
              <svg aria-hidden="true" className="fill-current w-15 h-15 ml-auto mr-10">
                <use href="/svg/svg.svg#man-user" />
              </svg>
              <span className="text-white">{usersCount}</span>
            </button>
          );
        })}
      </div>

      <div className="text-sm text-gray-400 flex items-center justify-center">
        <svg aria-hidden="true" className="fill-current w-12 h-12 mr-7">
          <use href="/svg/svg.svg#info" />
        </svg>
        {t("dice.betNotCanceled")}
      </div>
    </>
  );
};

DiceSelectRoom.propTypes = {
  rooms: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      usersCount: PropTypes.number.isRequired,
    }).isRequired,
  ).isRequired,
  activeRoomId: PropTypes.number,
  activeDeposit: PropTypes.number,
  setActiveRoom: PropTypes.func.isRequired,
};

DiceSelectRoom.defaultProps = {
  activeRoomId: null,
  activeDeposit: null,
};

export default DiceSelectRoom;
