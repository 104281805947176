export const CURRENT_STATE_URL = "/current-state";
export const REGISTRATION_URL = "/auth/registration";
export const VERIFY_EMAIL_URL = "/auth/verify-email";
export const LOGIN_URL = "/auth/login";
export const GET_VK_AUTH_URL_URL = "/auth/vk/get-url";
export const VK_SIGN_IN_URL = "/auth/vk/sign-in";
export const VK_LINK_URL = "/auth/vk/link";
export const SIGN_OUT_URL = "/auth/sign-out";
export const STEAM_GET_URL_URL = "/auth/steam/get-url";
export const STEAM_LINK_URL = "/auth/steam/link";
export const STEAM_SIGN_IN_URL = "/auth/steam/sign-in";
export const FACEBOOK_GET_URL_URL = "/auth/facebook/get-url";
export const FACEBOOK_LINK_URL = "/auth/facebook/link";
export const FACEBOOK_SIGN_IN_URL = "/auth/facebook/sign-in";
export const UNLINK_ACCOUNT_URL = "/auth/unlink-account";
export const AUTH_USER_UPDATE_LANG_URL = "/user/update-lang";
export const AUTH_USER_UPDATE_CLIENT_SEED_URL = "/user/update-client-seed";
export const AUTH_USER_CHANGE_REFERRER = "/user/change-referrer";
export const UPDATE_TRADE_URL_URL = "/user/update-trade-url";
export const SET_ACTIVE_WALLET_URL = "/user/set-active-wallet";

export const MINES_URL = "/mines";
export const MINES_STATE_URL = "/mines/state";
export const MINES_STEP_URL = "/mines/step";
export const MINES_CASH_OUT_URL = "/mines/cash-out";
export const MINES_CHANGE_SERVER_SEED = "/mines/change-server-seed";

export const CRASH_STATE_URL = "/crash/state";
export const CRASH_MAKE_URL = "/crash/make";
export const CRASH_UPGRADE_URL = "/crash/upgrade";

export const TOWERS_STATE_URL = "/towers/state";
export const TOWERS_GAMEBOARD_URL = "/towers/gameboard";
export const TOWERS_CREATE_URL = "/towers/create";
export const TOWERS_STEP_URL = "/towers/step";
export const TOWERS_CASHOUT_URL = "/towers/cash-out";
export const TOWERS_CHANGE_SERVER_SEED_URL = "/towers/change-server-seed";

export const ROULETTE_STATE_URL = "/roulette/state";
export const ROULETTE_MAKE_URL = "/roulette/make";

export const DICE_STATE_URL = "/dice/state";
export const DICE_URL = "/dice/";

export const COINFLIP_STATE_URL = "/coinflip/state";
export const COINFLIP_CREATE_URL = "/coinflip/create";
export const COINFLIP_URL = "/coinflip";

export const VALIDATION_CHECK_EMAIL_UNIQUE = "/validation/check-email-unique";

export const CHAT_URL_PREFIX = "/chat";

export const CASINOPUB_CREATE_SESSION_URL = "/casinopub/create-session";

export const CREATE_DEPOSIT_URL = "/deposit/";
export const LOAD_CRYPTO_WALLET_URL = "/deposit/crypto";

export const READ_NOTIFICATIONS_URL = "/user/read-notifications";

export const REFERRAL_STATE_URL = "/user/referral/state";
export const REFERRAL_WITHDRAW_URL = "/user/referral/withdraw";

export const CHANGE_EMAIL_CREATE_URL = "/user/change-email/create";
export const CHANGE_EMAIL_CONFIRM_URL = "/user/change-email/confirm";

export const CHANGE_PASSWORD_URL = "/user/change-password";
export const UPDATE_BOOLEAN_FIELD_URL = "/user/update-boolean-field";

export const USER_DESTROY_URL = "/user/destroy";

export const USER_VERIFICATION_UPLOAD_FILE_URL = "/user/verification/upload";
export const USER_VERIFICATION_STATE_URL = "/user/verification/state";

export const USER_PERSONAL_INFO_SAVE_URL = "/user/personal-info/save";

export const SEND_TIP = "/send-tip";

export const WITHDRAW_SKIN_URL = "/withdraw/skin";