import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useApi } from "@/api";
import noty from "@/libs/noty";
import {
  DICE_ROUND_WAITING_STATUS,
  DICE_ROUND_ROLLING_STATUS,
  DICE_ROUND_BETTING_STATUS,
} from "@/constants/diceRoundStatuses";
import DiceControl from "@/components/games/dice/DiceControl";
import { DICE_ROUND_BET_WAITING_STATUS } from "@/constants/diceRoundBetStatuses";

const DiceControlContainer = () => {
  const { t } = useTranslation();
  const { diceRoomBet, diceRoomRoll } = useApi();
  const activeRoom = useSelector(({ dice }) => dice.activeRoom);
  const user = useSelector(({ auth }) => auth.user);
  const userId = user ? user.id : null;

  const roomUsers = activeRoom ? activeRoom.users || [] : [];

  const place = roomUsers.findIndex(user => user && user.id === userId);

  const isJoinRoom = place > -1;
  const haveBet =
    isJoinRoom && activeRoom.round.bets
      ? activeRoom.round.bets.find(bet => userId === bet.userId)
      : null;

  const diceDisabled =
    !userId ||
    !isJoinRoom ||
    activeRoom.round.status !== DICE_ROUND_ROLLING_STATUS ||
    !haveBet ||
    (haveBet && haveBet.status !== DICE_ROUND_BET_WAITING_STATUS);

  const placeBetDisabled =
    !userId ||
    haveBet ||
    !isJoinRoom ||
    ![DICE_ROUND_WAITING_STATUS, DICE_ROUND_BETTING_STATUS].includes(activeRoom.round.status);

  const bet = () => {
    if (user.balance < activeRoom.deposit) {
      noty.error(t("notEnoughBalance"));
      return;
    }
    diceRoomBet(activeRoom.id).catch(() => {
      noty.error(t("dice.betError"));
    });
  };

  const roll = () => {
    diceRoomRoll(activeRoom.id).catch(() => {
      noty.error(t("dice.rollError"));
    });
  };

  return (
    <DiceControl
      onClickDice={roll}
      onClickPlaceBet={bet}
      diceDisabled={diceDisabled}
      placeBetDisabled={placeBetDisabled}
      showPlace={isJoinRoom && !haveBet}
      showDice={isJoinRoom && !!haveBet}
    />
  );
};

export default DiceControlContainer;
