import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import CopyToClipboard from "react-copy-to-clipboard";
import WalletImage from "@/components/Wallet/WalletImage";
import PageLoader from "@/components/PageLoader";

const DepositCrypto = ({ availableWalletTypes, wallets, loading, onLoad }) => {
  const { t } = useTranslation();
  const ref = useRef();
  const [active, setActive] = useState(null);
  const [height, setHeight] = useState(0);
  const [isCopy, setIsCopy] = useState(false);

  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.scrollHeight);
    }
  }, [active]);

  const onCopy = () => {
    setIsCopy(true);
    setTimeout(() => {
      setIsCopy(false);
    }, 2000);
  };

  return (
    <ul>
      {availableWalletTypes.map(type => {
        const wallet = wallets.find(w => w.type === type);

        const isActive = active === type;

        return (
          <li key={type} className="border-b-2 border-gray-100">
            <button
              type="button"
              className="btn w-full py-20 hover:text-black"
              onClick={() => {
                setActive(isActive ? null : type);
                if (!wallet) {
                  onLoad(type).then(isLoaded => {
                    if (!isLoaded) {
                      setActive(null);
                    }
                  });
                }
              }}
            >
              <WalletImage
                type={type}
                className="w-18 h-18 object-contain object-center mr-12 -mt-2"
              />
              <span className="text-xl">{t(`wallet.names.${type}`)}</span>
              <svg
                aria-hidden="true"
                className={`w-15 h-10 fill-current ml-auto transition transform ${
                  isActive ? "-scale-y-100 fill-green" : ""
                }`}
              >
                <use href="/svg/svg.svg#arrow-down" />
              </svg>
            </button>

            <div
              className="transition-all duration-300 overflow-hidden max-h-0"
              ref={isActive ? ref : null}
              style={{ maxHeight: isActive ? `${height}px` : "0px" }}
            >
              <div className="pt-6 pb-20">
                {loading && (
                  <PageLoader
                    style={{ position: "relative", background: "white", minHeight: "183px" }}
                  />
                )}
                {wallet && (
                  <>
                    <div className="flex">
                      {!wallet.tag && (
                        <div className="w-[12.8rem] h-[12.8rem]">
                          <QRCode value={wallet.address} size={128} />
                        </div>
                      )}
                      <div className="pl-20">
                        <div className="text-gray-500">{t("wallet.deposit.address")}</div>
                        <div className="text-black-900 my-15 break-all">{wallet.address}</div>
                        {wallet.tag && (
                          <>
                            <div className="text-gray-500">{t("wallet.deposit.tag")}</div>
                            <div className="text-black-900 my-15 break-all">{wallet.tag}</div>
                          </>
                        )}
                        <div className="text-gray-600">
                          {t(`wallet.deposit.description.${wallet.type}`)}
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-end space-x-12 mt-15 sm:-mt-3">
                      <CopyToClipboard text={wallet.address} onCopy={onCopy}>
                        <button type="button" className="btn btn--green btn--sm btn--rounded">
                          {t("wallet.deposit.copy")}
                        </button>
                      </CopyToClipboard>
                    </div>
                    <div className="text-right mt-10 mb-20">
                      {isCopy && (
                        <div className="text-green inline-flex items-center">
                          <svg aria-hidden="true" className="fill-current w-12 h-12 mr-10">
                            <use href="/svg/svg.svg#basic-tick" />
                          </svg>
                          {t("referral.isCopy")}
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

DepositCrypto.propTypes = {
  availableWalletTypes: PropTypes.arrayOf(PropTypes.number).isRequired,
  wallets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      type: PropTypes.number.isRequired,
      address: PropTypes.string,
      tag: PropTypes.string,
    }),
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  onLoad: PropTypes.func.isRequired,
};

export default DepositCrypto;
