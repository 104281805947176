import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import noty from "@/libs/noty";
import WalletPrefix from "@/components/Wallet/WalletPrefix";
import getToFixed from "@/components/Wallet/getToFixed";
import { WALLET_USDT_TYPE } from "@/constants/walletTypes";

const Input = ({ value, setValue, className, precision, type, disabled, customRef, hasError }) => {
  return (
    <input
      ref={customRef}
      type={type}
      disabled={disabled}
      className={`text-field ${className} ${hasError ? "border-red" : ""}`}
      placeholder="0.00"
      value={value}
      onChange={e => {
        setValue(e.target.value);
      }}
      onBlur={e => {
        if (!e.target.value) {
          setValue("");
        } else {
          setValue(Number(e.target.value).toFixed(precision));
        }
      }}
    />
  );
};

Input.propTypes = {
  setValue: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  precision: PropTypes.number,
  hasError: PropTypes.bool,
  customRef: PropTypes.shape({}),
};

Input.defaultProps = {
  disabled: false,
  value: "",
  className: "",
  type: "text",
  precision: 2,
  hasError: false,
  customRef: null,
};

const CrashAutoBet = ({
  balance,
  isStarted,
  defaultDeposit,
  defaultCount,
  onSubmit,
  onStop,
  walletType,
  inMoney,
}) => {
  const { t } = useTranslation();
  const [deposit, setDeposit] = useState(Number(defaultCount || 0).toFixed(getToFixed(walletType)));
  const [autoCoefficient, setAutoCoefficient] = useState("");
  const [count, setCount] = useState(defaultCount);
  const [onWinPercent, setOnWinPercent] = useState("");
  const [onWinIncrease, setOnWinIncrease] = useState(false);
  const [onLosePercent, setOnLosePercent] = useState("");
  const [onLoseIncrease, setOnLoseIncrease] = useState(false);
  const [stopOnProfit, setStopOnProfit] = useState("");
  const [stopOnLose, setStopOnLose] = useState("");

  const depositRef = useRef();
  const autoCoefficientRef = useRef();

  const precision = getToFixed(walletType, inMoney);

  useEffect(() => {
    setCount(defaultCount);
  }, [defaultCount]);

  useEffect(() => {
    setDeposit(Number(defaultDeposit > 0 ? defaultDeposit : 0).toFixed(precision));
  }, [defaultDeposit, precision]);

  const showError = (ref, name) => {
    ref.current.focus();
    noty.error(t(`crash.autobet.errors.${name}`));
  };

  const submit = () => {
    if (Number(deposit) <= 0) {
      showError(depositRef, "deposit");
      return;
    }

    if (Number(autoCoefficient) <= 1.0) {
      showError(autoCoefficientRef, "autoCoefficient");
      return;
    }

    onSubmit({
      deposit,
      autoCoefficient,
      count,
      onWinPercent,
      onWinIncrease,
      onLosePercent,
      onLoseIncrease,
      stopOnProfit,
      stopOnLose,
    });
  };

  return (
    <div className="grid grid-cols-2 gap-x-18 gap-y-20 mb-auto pb-25">
      <div className="col-span-full">
        <div className="text-lg">{t("crash.autobet.amount")}</div>
        <div className="block relative">
          <span className="absolute left-0 top-1/2 transform -translate-y-1/2 -mt-1 text-gray-600">
            {" "}
            <WalletPrefix type={walletType} />{" "}
          </span>
          <Input
            percision={precision}
            customRef={depositRef}
            className={`pl-${walletType === WALLET_USDT_TYPE ? "20" : "30"} pr-100`}
            disabled={isStarted}
            value={String(deposit || "")}
            setValue={setDeposit}
            precision={precision}
          />
          <div className="absolute top-11 right-0 flex">
            <button
              type="button"
              disabled={isStarted}
              className="btn text-lg p-6 text-gray-600 hover:text-white"
              onClick={() => {
                if (deposit > 0) {
                  setDeposit(Number(deposit / 2).toFixed(precision));
                }
              }}
            >
              ½
            </button>
            <button
              type="button"
              disabled={isStarted}
              className="btn text-lg p-6 text-gray-600 hover:text-white"
              onClick={() => {
                if (deposit > 0) {
                  setDeposit(Number(deposit * 2).toFixed(precision));
                }
              }}
            >
              2x
            </button>
            <button
              type="button"
              disabled={isStarted}
              className="btn text-lg p-6 text-gray-600 hover:text-white"
              onClick={() => {
                setDeposit(Number(balance).toFixed(precision));
              }}
            >
              Max
            </button>
          </div>
        </div>
      </div>
      <label>
        <div className="text-lg">{t("crash.autobet.autoCoefficient")}</div>
        <div className="block relative">
          <span className="absolute left-0 top-1/2 transform -translate-y-1/2 -mt-1 text-gray-600">
            {" "}
            X{" "}
          </span>
          <Input
            className="pl-20"
            customRef={autoCoefficientRef}
            disabled={isStarted}
            value={autoCoefficient}
            setValue={setAutoCoefficient}
          />
        </div>
      </label>
      <label>
        <div className="text-lg">{t("crash.autobet.count")}</div>
        <div className="block relative">
          {[0, "0", "0.00"].includes(count) && (
            <span className="absolute right-0 top-1/2 transform -translate-y-1/2 -mt-1 text-gray-600 text-4xl">
              ∞
            </span>
          )}
          <Input
            className="pr-20"
            disabled={isStarted}
            value={String(count)}
            setValue={setCount}
            type="number"
            fixed={0}
          />
        </div>
      </label>
      <div className="col-span-full">
        <div className="text-lg">{t("crash.autobet.onWin")}</div>
        <div className="block relative">
          <span className="absolute left-0 top-1/2 transform -translate-y-1/2 -mt-1 text-gray-600">
            {" "}
            %{" "}
          </span>
          <Input
            className="pl-20 pr-100"
            disabled={isStarted}
            value={onWinPercent}
            setValue={setOnWinPercent}
          />
          <div className="absolute top-10 right-0 flex">
            <button
              type="button"
              disabled={isStarted}
              className={`btn px-15 rounded-tiny ${
                onWinIncrease ? "btn--tiny btn--green" : "text-[#c6c6c6] hover:text-white"
              }`}
              onClick={() => setOnWinIncrease(true)}
            >
              {t("crash.autobet.increase")}
            </button>
            <button
              type="button"
              disabled={isStarted}
              className={`btn ${
                !onWinIncrease ? "btn--tiny btn--green" : "text-[#c6c6c6] hover:text-white"
              } px-15 rounded-tiny`}
              onClick={() => setOnWinIncrease(false)}
            >
              {t("crash.autobet.reset")}
            </button>
          </div>
        </div>
      </div>
      <div className="col-span-full">
        <div className="text-lg">{t("crash.autobet.onLose")}</div>
        <div className="block relative">
          <span className="absolute left-0 top-1/2 transform -translate-y-1/2 -mt-1 text-gray-600">
            {" "}
            %{" "}
          </span>
          <Input
            className="pl-20 pr-100"
            disabled={isStarted}
            value={onLosePercent}
            setValue={setOnLosePercent}
          />
          <div className="absolute top-10 right-0 flex">
            <button
              type="button"
              disabled={isStarted}
              className={`btn px-15 rounded-tiny ${
                onLoseIncrease ? "btn--tiny btn--green" : "text-[#c6c6c6] hover:text-white"
              }`}
              onClick={() => setOnLoseIncrease(true)}
            >
              {t("crash.autobet.increase")}
            </button>
            <button
              type="button"
              disabled={isStarted}
              className={`btn ${
                !onLoseIncrease ? "btn--tiny btn--green" : "text-[#c6c6c6] hover:text-white"
              } px-15 rounded-tiny`}
              onClick={() => setOnLoseIncrease(false)}
            >
              {t("crash.autobet.reset")}
            </button>
          </div>
        </div>
      </div>
      <label>
        <div className="text-lg">{t("crash.autobet.stopOnProfit")}</div>
        <div className="block relative">
          <span className="absolute left-0 top-1/2 transform -translate-y-1/2 -mt-1 text-gray-600">
            {" "}
            <WalletPrefix type={walletType} />{" "}
          </span>
          <Input
            className={`pl-${walletType === WALLET_USDT_TYPE ? "20" : "30"}`}
            disabled={isStarted}
            value={stopOnProfit}
            setValue={setStopOnProfit}
            precision={precision}
          />
        </div>
      </label>
      <label>
        <div className="text-lg">{t("crash.autobet.stopOnLose")}</div>
        <div className="block relative">
          <span className="absolute left-0 top-1/2 transform -translate-y-1/2 -mt-1 text-gray-600">
            {" "}
            <WalletPrefix type={walletType} />{" "}
          </span>
          <Input
            className={`pl-${walletType === WALLET_USDT_TYPE ? "20" : "30"}`}
            disabled={isStarted}
            value={stopOnLose}
            setValue={setStopOnLose}
            precision={precision}
          />
        </div>
      </label>
      <button
        type="button"
        className={`btn btn--md btn--rounded ${
          isStarted ? "btn--green" : "btn--primary"
        } col-span-full mt-5`}
        onClick={() => {
          if (isStarted) {
            onStop();
          } else {
            submit();
          }
        }}
      >
        {t(`crash.autobet.${isStarted ? "stop" : "start"}`)}
      </button>
    </div>
  );
};

CrashAutoBet.propTypes = {
  balance: PropTypes.number.isRequired,
  isStarted: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onStop: PropTypes.func.isRequired,
  defaultCount: PropTypes.number,
  defaultDeposit: PropTypes.number,
  walletType: PropTypes.number.isRequired,
  inMoney: PropTypes.bool.isRequired,
};

CrashAutoBet.defaultProps = {
  defaultCount: 0,
  defaultDeposit: 0,
};

export default CrashAutoBet;
