import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import toLocaleDate from "@/helpers/toLocaleDate";

const LiveBets = ({ all, high, lucky, count }) => {
  const [active, setActive] = useState("all");
  const { t } = useTranslation();

  const bets = [
    {
      id: "all",
      bets: all,
    },
    {
      id: "high",
      bets: high,
    },
    {
      id: "lucky",
      bets: lucky,
    },
  ];

  const activeIndex = bets.findIndex(({ id }) => id === active);

  const activeBets = bets[activeIndex || 0].bets;

  return (
    <section className="bg-black pb-40 games-footer">
      <div className="container">
        <div className="lg:flex items-center justify-between px-10">
          <nav className="secondary-nav secondary-nav--has-marker">
            <ul data-menu="">
              {bets.map(({ id }) => (
                <li key={id}>
                  <button
                    type="button"
                    className={`secondary-nav__link text-xl ${active === id ? "active" : ""}`}
                    onClick={() => setActive(id)}
                  >
                    {t(`live.buttons.${id}`)}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
          <div className="mb-20 mt-10 lg:m-0">
            <span className="text-gray mr-5">{t("live.wagerAllTimes")}</span>
            <span className="text-gray-light">{count.toLocaleString("en-US")}</span>
          </div>
        </div>

        <table className="table">
          <thead>
            <tr>
              <th>{t("live.table.gameType")}</th>
              <th className="hidden md:table-cell">{t("live.table.username")}</th>
              <th className="hidden md:table-cell">{t("live.table.time")}</th>
              <th className="hidden md:table-cell">{t("live.table.deposit")}</th>
              <th className="hidden md:table-cell">{t(t("live.table.coefficient"))}</th>
              <th>{t(t("live.table.withdraw"))}</th>
            </tr>
          </thead>
          <tbody>
            {activeBets.map(({ id, type, user, date, deposit, coefficient, withdraw, isWin }) => {
              return (
                <tr key={id}>
                  <td>{t(`live.table.type.${type}`)}</td>
                  <td className="hidden md:table-cell text-gray-light">
                    {user ? user.name : t("hidden")}
                  </td>
                  <td className="hidden md:table-cell text-gray">{toLocaleDate(date, "HH:mm")}</td>
                  <td className="hidden md:table-cell">$ {Number(deposit).toFixed(2)}</td>
                  <td className="hidden md:table-cell text-green">
                    {Number(coefficient).toFixed(2)}x
                  </td>
                  <td>
                    <div
                      className={`inline-block rounded-circle w-5 h-5 ${
                        isWin ? "bg-green" : "bg-red"
                      } mr-10 align-middle`}
                    />
                    $ {withdraw.toFixed(2)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </section>
  );
};

const betProps = PropTypes.shape({
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
  date: PropTypes.string.isRequired,
  deposit: PropTypes.number.isRequired,
  coefficient: PropTypes.number.isRequired,
  withdraw: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isWin: PropTypes.bool.isRequired,
});

LiveBets.propTypes = {
  count: PropTypes.number.isRequired,
  all: PropTypes.arrayOf(betProps).isRequired,
  high: PropTypes.arrayOf(betProps).isRequired,
  lucky: PropTypes.arrayOf(betProps).isRequired,
};

export default LiveBets;
