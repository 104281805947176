import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setActiveRoom } from "@/actions/diceAction";
import DiceSelectRoom from "@/components/games/dice/DiceSelectRoom";
import DiceControlContainer from "@/containers/games/dice/DiceControlContainer";
import LuckyWins from "@/components/games/GameLuckyWins";

const DiceSidebarContainer = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const rooms = useSelector(({ dice }) => dice.rooms);
  const activeRoom = useSelector(({ dice }) => dice.activeRoom);
  const luckyWins = useSelector(({ dice }) => dice.luckyWins);

  return (
    <div className="game-wrapper__sidebar">
      <div className="game-sidebar-inner">
        <div>
          {rooms.length && (
            <DiceSelectRoom
              rooms={rooms}
              activeRoomId={activeRoom ? activeRoom.id : null}
              activeDeposit={activeRoom ? activeRoom.deposit : null}
              setActiveRoom={room => {
                dispatch(setActiveRoom(room));
                history.push(`/dice/${room.id}`)
              }}
            />
          )}
          <DiceControlContainer />
          <LuckyWins items={luckyWins} />
        </div>
      </div>
    </div>
  );
};

export default DiceSidebarContainer;
