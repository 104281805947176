export default {
  tab: {
    crypto: "Crypto",
    money: "Money",
    skins: "Skins",
  },
  loadMore: "Load more",
  filter: {
    name: "Fill in item name",
    namePlaceholder: "Fill in item name",
    price: "Max Price",
    pricePlaceholder: "Max Price",
  },
  tradeUrl: {
    title: "Enter Trade-URL",
    helpLink: "Find out the link to the exchange",
    submit: "Save",
    success: "You have successfully changed your Trade-URL!",
    fail: "Failed to change Trade-URL, try again later!",
    wrongFormat: "Failed to change Trade-URL, wrong form!",
    accessForbidden: "You cannot this Trade URL!",
  },
  button: "Withdraw",
  success: "You have added {{ name }} with the price {{ price }} in queue",
  errors: {
    manySelectedItems: "Only 1 item can be withdrawn at the same time",
    fail: "Could not withdraw the item, try later",
    email: "Wrong format email",
    REQUIRE_DEPOSITS: "Deposit at least once",
    ERROR_PERMISSIONS: "You can't withdraw items",
    TRADE_URL_NOT_PROVIDED: "You haven't put your tradelink. Do it in your profile.",
    CAN_NOT_HANDLE_ITEM: "The item can not be withdrawn at the moment. You should exchange it",
    TOO_MANY_ALREADY: "You have too many withdrawals at once",
    TOO_POOR_BETS: "You have to do more bets to make withdrawals",
    GOODASLY_EMAIL_NOT_FOUND: "The account with such mail is not registered on Goodasly",
    ITEM_NOT_FOUND: "Item not found",
  },
};
