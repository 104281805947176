import React from "react";
import { useTranslation } from "react-i18next";
import useScrollTop from "@/helpers/useScrollTop";

const VipPage = () => {
  const { t } = useTranslation();

  useScrollTop();

  return (
    <div className="md:text-right md:py-40 lg:py-70 relative">
      <div className="md:pl-300 lg:pl-450">
        <div className="text-4xl md:text-6xl font-bold mb-10 md:mb-0">
          {t("vip.title")}
        </div>
        <div className="text-2xl md:text-4xl">
          {t("vip.subtitle")}
        </div>
        <div className="my-20 md:my-40 lg:my-60 h-3 bg-black w-full"/>
        <div className="text-2xl space-y-15" dangerouslySetInnerHTML={{ __html: t("vip.content") }} />
        <img src="/img/god-midas.svg" alt="God midas"
             className="w-140 h-40 object-contain object-center inline-block mt-40 md:mt-70"/>
      </div>
      <img src="/img/girl.png" alt=""
           className="w-500 md:h-500 lg:w-650 lg:h-650 object-contain object-bottom md:absolute left-0 bottom-0 -mb-45 md:-ml-80 mt-20 md:mt-0 pointer-events-none"/>
    </div>
  );
};

export default VipPage;