import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import InputDeposit from "@/components/games/InputDeposit";
import getToFixed from "@/components/Wallet/getToFixed";
import AutoCoefficientSettings from "./AutoCoefficientSettings";
import WalletPrefix from "@/components/Wallet/WalletPrefix";

const CrashControl = ({
  walletType,
  inMoney,
  disabledInput,
  defaultAutoCoefficient,
  isUpgrade,
  isStarting,
  upgrade,
  onCreate,
  autoCoefficientSettings,
  onChangeAutoCoefficient,
  onChangeAutoCoefficientSettings,
  havePendingBet,
  onClearPendingBet,
}) => {
  const { t } = useTranslation();
  const [deposit, setDeposit] = useState(Number(0).toFixed(getToFixed(walletType)));
  const [autoManualCoefficient, setAutoManualCoefficient] = useState("");
  const [autoCoefficient, setAutoCoefficient] = useState(defaultAutoCoefficient);

  const isDemo = !havePendingBet && !isStarting && !isUpgrade && Number(deposit) === 0;

  return (
    <div>
      <InputDeposit
        label={t("crash.create.amount")}
        walletType={walletType}
        value={deposit}
        onChange={setDeposit}
      />

      <div className="text-lg">{t("crash.create.autoCoefficient")}</div>
      <label htmlFor="crash-auto-coefficient" className="block relative mb-20">
        <span className="absolute left-0 top-1/2 transform -translate-y-1/2 -mt-1 text-gray-600">
          X
        </span>
        <input
          type="text"
          id="crash-auto-coefficient"
          className="text-field pl-20"
          placeholder="0.00"
          value={autoManualCoefficient}
          onChange={e => {
            if (!disabledInput) {
              setAutoManualCoefficient(e.target.value);
            }
          }}
          onBlur={e => {
            if (e.target.value) {
              const newValue = Number(e.target.value).toFixed(2);
              setAutoManualCoefficient(newValue);
              onChangeAutoCoefficient(newValue);
            } else {
              setAutoManualCoefficient("");
              onChangeAutoCoefficient(2);
            }
          }}
        />
      </label>

      <div className="text-gray-600 mb-15">{t("crash.create.selectAutoCoefficient")}</div>

      <AutoCoefficientSettings
        value={autoManualCoefficient || autoCoefficient}
        onSelect={coefficient => {
          if (!disabledInput) {
            setAutoManualCoefficient("");
            setAutoCoefficient(coefficient);
          }
        }}
        defaultCoefficients={autoCoefficientSettings}
        onSetNewCoefficients={onChangeAutoCoefficientSettings}
      />

      {isUpgrade && upgrade}
      {!isUpgrade && !isStarting && !havePendingBet && (
        <button
          type="button"
          className={`btn btn--md btn--rounded w-full justify-between mt-22 btn--${
            isDemo ? "green" : "primary"
          }`}
          onClick={() => {
            onCreate({
              deposit: isDemo ? 0 : deposit,
              autoCoefficient: autoManualCoefficient || autoCoefficient,
            });
          }}
        >
          <span>{t(`crash.create.${isDemo ? "playDemo" : "button"}`)}</span>
          {!isDemo && (
            <span>
              <WalletPrefix type={walletType} /> {Number(deposit).toFixed(getToFixed(walletType))}
            </span>
          )}
        </button>
      )}
      {isStarting && (
        <button type="button" className="btn btn--md btn--rounded w-full justify-between mt-22">
          <span>{t("crash.create.starting")}</span>
        </button>
      )}

      {havePendingBet && (
        <button
          type="button"
          className="btn btn--md btn--rounded btn--red w-full justify-between mt-22"
          onClick={() => onClearPendingBet()}
        >
          <span>{t("crash.create.cancel")}</span>
        </button>
      )}

      <div className="text-sm text-gray-400 mt-12 flex items-center justify-center">
        <svg aria-hidden="true" className="fill-current w-12 h-12 mr-7">
          <use href="/svg/svg.svg#info" />
        </svg>
        {t("crash.create.demo")}
      </div>
    </div>
  );
};

CrashControl.propTypes = {
  defaultAutoCoefficient: PropTypes.string.isRequired,
  onCreate: PropTypes.func.isRequired,
  autoCoefficientSettings: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChangeAutoCoefficientSettings: PropTypes.func.isRequired,
  onChangeAutoCoefficient: PropTypes.func.isRequired,
  isUpgrade: PropTypes.bool.isRequired,
  upgrade: PropTypes.node.isRequired,
  isStarting: PropTypes.bool.isRequired,
  disabledInput: PropTypes.bool.isRequired,
  havePendingBet: PropTypes.bool.isRequired,
  onClearPendingBet: PropTypes.func.isRequired,
};

export default CrashControl;
