import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import useSubscribe from "@/libs/centrifuge/useSubscribe";
import {
  setCrash,
  setDelta,
  setStatus,
  setStatistic,
  addHistory,
  setHash,
} from "@/actions/crashAction";

const CrashSubscriberContainer = () => {
  const dispatch = useDispatch();
  const subscribe = useSubscribe();

  useEffect(() => {
    const unsubscribe = subscribe("game", ({ type, ...payload }) => {
      switch (type) {
        case "start": {
          dispatch(setHash(payload.h));
          break;
        }

        case "u": {
          dispatch(setDelta(payload.d));
          dispatch(setStatus(payload.s));
          break;
        }

        case "s": {
          if(payload.s === 1) {
            dispatch(setDelta(payload.d));
          }
          dispatch(setStatus(payload.s));
          break;
        }

        case "c": {
          dispatch(setStatus(payload.s));
          dispatch(setDelta(payload.d));
          dispatch(setCrash(payload.c));
          dispatch(
            addHistory({
              id: payload.i,
              crash: payload.c,
            }),
          );
          break;
        }

        case "st": {
          const { c, td, ti } = payload;
          dispatch(
            setStatistic({
              count: c,
              totalDeposit: td,
              totalItems: ti,
            }),
          );
          break;
        }

        default:
          break;
      }
    });

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  return <></>;
};

export default CrashSubscriberContainer;
