import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useApi } from "@/api";
import noty from "@/libs/noty";
import WithdrawSkinsSelectItemContainer from "@/containers/withdraw/WithdrawSkinsSelectItemContainer";
import WithdrawTradeLinkContainer from "@/containers/withdraw/WithdrawTradeLinkContainer";

const WithdrawSkinsContainer = () => {
  const [item, setItem] = useState();
  const { t } = useTranslation();
  const [pending, setPending] = useState(false);

  const { withdrawSkin } = useApi();

  const showError = key => {
    noty.error(t(`withdraw.errors.${key}`));
  };

  const showSuccess = item => {
    noty.success(t("withdraw.success", { name: item.name, price: item.price }));
  };

  const withdraw = () => {
    setPending(true);

    return withdrawSkin({ itemId: item.id })
      .then(() => {
        setPending(false);
        showSuccess(item);
        setItem(null);
        return true;
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 403) {
            showError(error.response.data.error);
          }
          if (error.response.status === 422) {
            showError("email");
          }
        } else {
          showError("fail");
        }
        return false;
      })
      .finally(() => {
        setPending(false);
      });
  };

  return (
    <>
      <WithdrawSkinsSelectItemContainer selectedItem={item} onSelectItem={setItem} />
      <WithdrawTradeLinkContainer />
      <button
        type="button"
        className="btn btn--green btn--bold btn--md btn--rounded mt-20 sm:mt-10"
        disabled={pending || !item}
        onClick={withdraw}
      >
        {t("withdraw.button")} <span className="ml-15">-</span>
      </button>
    </>
  );
};

export default WithdrawSkinsContainer;
