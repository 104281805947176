export default {
  title: "Coinflip",
  betAmount: "Bet amount",
  create: "Create Coifnlip",
  createSuccess: "Success create",
  createFail: "Fail create",
  join: {
    submit: "Entry in Game",
  },
  watch: "Watch",
  successJoin: "Success join",
  failJoin: "Fail join",
  roundDesc: "Entry in Game",
  back: "Back",
  winner: "Winner",
  start: {
    title: "Game",
    sub: "Starting in",
  },
  choose: "Choose",
  luckyWins: "Last Lucky Wins",
}