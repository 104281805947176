import React, { useState } from "react";
import PropTypes from "prop-types";

const TabPanel = ({ items }) => {
  const [active, setActive] = useState(items[0]);

  return (
    <>
      <div className="flex space-x-5 sm:space-x-20 mb-25">
        {items.map(item => {
          const { id, label } = item;
          return (
            <button
              key={id}
              type="button"
              onClick={() => setActive(item)}
              className={`btn btn--sm btn--rounded text-xl border-2 hover:text-black-800 [ ${
                active.id === id
                  ? "border-green text-black-900 pointer-events-none"
                  : "border-transparent"
              } ]`}
            >
              {label}
            </button>
          );
        })}
      </div>
      <>{active.component}</>
    </>
  );
};

TabPanel.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      component: PropTypes.node.isRequired,
    }),
  ).isRequired,
};

export default TabPanel;
