export default bet => {

  return {
    id: bet.i,
    gameId: bet.g,
    status: bet.s,
    coefficientAuto: bet.a,
    createdAt: bet.ca,
    coefficient: bet.c,
    deposit: bet.d,
    withdraw: bet.w,
    user: {
      id: bet.u.id,
      name: bet.u.name,
    },
    walletType: bet.walletType,
  };
};
