export default {
  title: "Mines",
  create: {
    deposit: "Bet amount",
    bombs: "Number Of Mines",
    demo: "Play demo",
    description: "Betting $0.00 will enter demo mode",
    submit: "Place Bet",
    error: {
      default: "Failed to create round.",
      THERE_IS_ALREADY_AN_ACTIVE_ROUND: "There is already an active round. Refresh page",
    },
    success: "Success!",
  },
  cashOut: {
    button: "Cash-Out",
    error: "Cash-out error. Refresh and retry.",
  },
  lastRounds: "Last Lucky Wins",
  changeServerSeed: {
    fail: "Failed to modify Server Seed. try later",
    failInRound: "Server Seed cannot be changed during a round.",
  },
  stepError: "Failed. Repeat.",
};
