import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useApi } from "@/api";
import { setActiveRoom } from "@/actions/diceAction";
import noty from "@/libs/noty";
import DiceRoom from "@/components/games/dice/DiceRoom";

const DiceRoomContainer = () => {
  const { t } = useTranslation();
  const { diceRoomState, diceRoomJoin, diceRoomLeave } = useApi();
  const dispatch = useDispatch();
  const activeRoomId = useSelector(({ dice }) => (dice.activeRoom ? dice.activeRoom.id : null));
  const activeRoom = useSelector(({ dice }) => dice.activeRoom);
  const user = useSelector(({ auth }) => auth.user);
  const userId = user ? user.id : null;

  useEffect(() => {
    if (!activeRoomId) {
      return;
    }
    diceRoomState(activeRoomId).then(room => {
      dispatch(setActiveRoom(room));
    });
  }, [activeRoomId]);

  if (!activeRoom.round) {
    return (
      <div className="game-wrapper__main">
        <div className="lg:h-620 pt-20 md:pt-50 pb-110 px-15 md:px-30 text-center" />
      </div>
    );
  }

  const join = place => {
    if (!userId) {
      return;
    }
    if (activeRoom.deposit > user.balance) {
      noty.error(t("notEnoughBalance"));

      return;
    }
    diceRoomJoin(activeRoomId, place).catch(() => {
      noty.error(t("dice.joinError"));
    });
  };

  const leave = () => {
    diceRoomLeave(activeRoomId).catch(() => {
      noty.error(t("dice.leaveError"));
    });
  };

  return <DiceRoom room={activeRoom} onJoin={join} onLeave={leave} authUserId={userId} />;
};

export default DiceRoomContainer;
