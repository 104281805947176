import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setRooms, setActiveRoom, setLuckyWins } from "@/actions/diceAction";
import { useApi } from "@/api";

const DiceCurrentStateContainer = () => {
  const dispatch = useDispatch();
  const { roomId } = useParams();
  const { diceState } = useApi();
  const [isLoaded, setIsLoaded] = useState(false);
  const user = useSelector(({ auth }) => auth.user);

  useEffect(() => {
    if (isLoaded) {
      return;
    }

    diceState()
      .then(({ rooms, luckyWins }) => {
        dispatch(setRooms(rooms));

        let activeRoom = rooms.find(room => room.id === Number(roomId));

        if (!activeRoom) {
          [activeRoom] = rooms;
        }

        if (user) {
          const activeRoomFound = rooms.find(room => room.isActive);
          if (activeRoomFound) {
            activeRoom = activeRoomFound;
          }
        }

        dispatch(setActiveRoom(activeRoom));
        dispatch(setLuckyWins(luckyWins));
      })
      .finally(() => {
        setIsLoaded(true);
      });
  }, [roomId]);

  return <></>;
};

export default DiceCurrentStateContainer;
