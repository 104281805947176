import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import GameHeaderContainer from "@/containers/games/GameHeaderContainer";
import GameFooterContainer from "@/containers/games/GameFooterContainer";
import CoinflipSidebarContainer from "@/containers/games/coinflip/CoinflipSidebarContainer";
import CoinflipSubscriberContainer from "@/containers/games/coinflip/CoinflipSubscriberContainer";
import CoinflipListContainer from "@/containers/games/coinflip/CoinflipListContainer";
import CoinflipRoundContainer from "@/containers/games/coinflip/CoinflipRoundContainer";

const CoinflipContainer = () => {
  const { t } = useTranslation();
  const activeRound = useSelector(({ coinflip }) => coinflip.activeRound);

  return (
    <>
      <GameHeaderContainer title={t("coinflip.title")} />
      <div className="game-wrapper game-wrapper--has-sidebar">
        <div className="game-wrapper__main">
          <CoinflipListContainer hide={!!activeRound} />
          {activeRound && <CoinflipRoundContainer />}
        </div>
        <div className="game-wrapper__sidebar">
          <CoinflipSidebarContainer />
        </div>
      </div>
      <GameFooterContainer />
      <CoinflipSubscriberContainer />
    </>
  );
};

export default CoinflipContainer;
