import {
  AUTH_SET_TOKEN,
  AUTH_CLEAR,
  SET_USER,
  SET_USER_LANG,
  SET_USER_BALANCE,
  SET_USER_CLIENT_SEED,
  SET_USER_TRADE_URL,
  SET_NOTIFICATIONS,
  NEW_NOTIFICATION,
  UPDATE_USER,
  UPDATE_WALLET,
  SET_WALLET_IN_MONEY,
  SET_WALLET_CLOSE_ZERO,
} from "@/constants";

export const clear = () => {
  return {
    type: AUTH_CLEAR,
  };
};

export const setToken = token => {
  return {
    type: AUTH_SET_TOKEN,
    payload: token,
  };
};

export const setUser = user => {
  return {
    type: SET_USER,
    payload: user,
  };
};

export const updateUser = user => {
  return {
    type: UPDATE_USER,
    payload: user,
  };
};


export const updateWallet = wallet => {
  return {
    type: UPDATE_WALLET,
    payload: wallet,
  };
};

export const setBalance = balance => {
  return {
    type: SET_USER_BALANCE,
    payload: balance,
  };
};

export const setUserLang = lang => {
  return {
    type: SET_USER_LANG,
    payload: lang,
  };
};

export const setUserTradeUrl = tradeUrl => {
  return {
    type: SET_USER_TRADE_URL,
    payload: tradeUrl,
  };
};

export const setUserClientSeed = clientSeed => {
  return {
    type: SET_USER_CLIENT_SEED,
    payload: clientSeed,
  };
};

export const setNotifications = notifications => {
  return {
    type: SET_NOTIFICATIONS,
    payload: notifications,
  };
};

export const newNotification = notification => {
  return {
    type: NEW_NOTIFICATION,
    payload: notification,
  };
};

export const setInMoney = inMoney => {
  return {
    type: SET_WALLET_IN_MONEY,
    payload: inMoney,
  };
};

export const setCloseZero = closeZero => {
  return {
    type: SET_WALLET_CLOSE_ZERO,
    payload: closeZero,
  };
};
