import React from "react";
import { useTranslation } from "react-i18next";
import TabPanel from "@/components/TabPanel";
import DepositMoneyContainer from "@/containers/deposits/DepositMoneyContainer";
import DepositCryptoContainer from "@/containers/deposits/DepositCryptoContainer";
import DepositSkinsContainer from "@/containers/deposits/DepositSkinsContainer";

const DepositContainer = () => {
  const { t } = useTranslation();

  const items = [
    {
      id: "crypto",
      label: t("deposit.tab.crypto"),
      component: <DepositCryptoContainer />,
    },
    {
      id: "money",
      label: t("deposit.tab.money"),
      component: <DepositMoneyContainer />,
    },
    {
      id: "skins",
      label: t("deposit.tab.skins"),
      component: <DepositSkinsContainer />,
    },
  ];

  return <TabPanel items={items} />;
};

export default DepositContainer;
