import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { setRounds, setPositions, setBets } from "@/actions/coinflipAction";
import { useApi } from "@/api";
import CoinflipListRoundContainer from "@/containers/games/coinflip/CoinflipListRoundContainer";

let timer;

const pinnedPositions = new Array(10).fill(null).map((v, position) => 100 + position);

const CoupleGamesRoundsContainer = ({ hide }) => {
  const dispatch = useDispatch();

  const positions = useSelector(({ coinflip }) => coinflip.positions);
  const { coinflipState } = useApi();

  useEffect(() => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      coinflipState().then(result => {
        const newPositions = new Array(30).fill(null).map((v, position) => position);
        const rounds = new Array(130).fill(null).map((v, position) => {
          return result.rounds[position] || {};
        });

        dispatch(setPositions(newPositions));
        dispatch(setRounds(rounds));
        dispatch(setBets(result.bets));
      });
    }, 300);
  }, []);

  return (
    <div className={`lg:h-620 py-20 md:py-30 px-10 md:px-30 text-center ${hide ? "hidden" : ""}`}>
      <ul className="space-y-10 overflow-y-auto max-h-300 lg:max-h-full disable-scrollbars h-full rounded">
        {pinnedPositions.map(position => (
          <CoinflipListRoundContainer key={position} position={position} isEmpty />
        ))}
        {positions.map(position => (
          <CoinflipListRoundContainer key={position} position={position} />
        ))}
      </ul>
    </div>
  );
};

CoupleGamesRoundsContainer.propTypes = {
  hide: PropTypes.bool.isRequired,
};

export default CoupleGamesRoundsContainer;
