import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useSound from "@/helpers/useSound";
import TowersRound from "@/components/games/towers/TowersRound";
import { useApi } from "@/api";
import {
  setGameboard,
  setRound,
  setRoundBombCells,
  setRoundStatus,
  setRoundSteps,
} from "@/actions/towersAction";
import noty from "@/libs/noty";
import {
  TOWERS_PENDING_STATUS,
  TOWERS_REJECT_STATUS,
  TOWERS_RESOLVE_STATUS,
} from "@/constants/towersStatus";
import getToFixed from "@/components/Wallet/getToFixed";
import WalletPrefix from "@/components/Wallet/WalletPrefix";
import { WALLET_USDT_TYPE } from "@/constants/walletTypes";

const TowersRoundContainer = () => {
  const { towersGameboard, towersStep } = useApi();
  const dispatch = useDispatch();
  const sound = useSound();
  const { t } = useTranslation();

  const demo = useSelector(({ towers }) => towers.demo);
  const roundId = useSelector(({ towers }) => towers.round.id);
  const status = useSelector(({ towers }) => towers.round.status);
  const walletType = useSelector(({ towers }) => towers.round.walletType);
  const gameboard = useSelector(({ towers }) => towers.gameboard);
  const difficulty = useSelector(({ towers }) => towers.round.difficulty);
  const gameboardDeposit = useSelector(({ towers }) => towers.round.gameboardDeposit);
  const steps = useSelector(({ towers }) => towers.round.steps);
  const bombCells = useSelector(({ towers }) => towers.round.bombCells || 0);
  const currencies = useSelector(({ main }) => main.currencies);
  const inMoney = useSelector(({ auth }) => auth.walletSettings.inMoney);

  const rate = currencies[walletType];

  useEffect(() => {
    towersGameboard({
      difficulty: difficulty || 1,
      deposit: gameboardDeposit || 0,
      walletType,
    }).then(newGameboard => {
      if (newGameboard) {
        dispatch(setGameboard(newGameboard));
      }
    });
  }, [difficulty, gameboardDeposit, walletType, inMoney, rate]);

  if (!gameboard) {
    return <></>;
  }

  let activeRow = null;

  if (Number.isInteger(roundId)) {
    activeRow = steps.length;
  }

  const rows = new Array(gameboard.payouts.length)
    .fill(null)
    .map((v, index) => {
      const withdraw = gameboard.payouts[index];
      const isClose = status ? status !== TOWERS_PENDING_STATUS && activeRow <= index : false;

      const cells = new Array(gameboard.columns).fill(null).map((t, id) => {
        const isPoop = bombCells[index] ? Boolean(bombCells[index][id]) : false;
        return {
          id,
          withdraw: (
            <span className="whitespace-nowrap">
              <WalletPrefix type={inMoney ? WALLET_USDT_TYPE : walletType} />{" "}
              {(inMoney ? withdraw * rate : withdraw).toFixed(getToFixed(walletType, inMoney))}
            </span>
          ),
          isPoop,
        };
      });

      return {
        id: index,
        isClose,
        cells,
      };
    })
    .sort((a, b) => b.id - a.id);

  const demoStep = index => {
    if (demo[activeRow][index]) {
      dispatch(setRoundBombCells([...demo]));
      dispatch(setRoundStatus(TOWERS_REJECT_STATUS));
      sound.minesBomb();
    } else {
      dispatch(setRoundSteps([...steps, index]));
      dispatch(setRoundBombCells([...bombCells, demo[activeRow]]));
      if (demo.length === steps.length + 1) {
        dispatch(setRoundStatus(TOWERS_RESOLVE_STATUS));
        sound.minesWin();
        return;
      }

      sound.minesTile();
    }
  };

  const step = index => {
    towersStep(index)
      .then(updatedRound => {
        switch (updatedRound.status) {
          case TOWERS_RESOLVE_STATUS:
            sound.minesWin();
            break;
          case TOWERS_REJECT_STATUS:
            sound.minesBomb();
            break;
          default:
            sound.minesTile();
            break;
        }
        dispatch(setRound(updatedRound));
      })
      .catch(() => {
        noty.error(t("towers.step.error"));
      });
  };

  return <TowersRound rows={rows} activeRow={activeRow} onSelect={demo ? demoStep : step} />;
};

export default TowersRoundContainer;
