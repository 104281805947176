import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import useMenuAnimation from "@/helpers/useMenuAnimation";
import { WALLET_BTC_TYPE, WALLET_ETH_TYPE, WALLET_USDT_TYPE } from "@/constants/walletTypes";
import useOutsideClickHandler from "@/helpers/useOutsideClickHandler";
import WalletImage from "./WalletImage";
import WalletMoney from "./WalletMoney";

const cryptoWallets = [WALLET_BTC_TYPE, WALLET_ETH_TYPE];
const stableWallets = [WALLET_USDT_TYPE];

const tabs = [
  {
    id: "crypto",
    wallets: cryptoWallets,
  },
  {
    id: "stable",
    wallets: stableWallets,
  },
];

const Wallet = ({
  currencies,
  userActiveWallet,
  userWallets,
  inMoney,
  closeZero,
  onSelectActiveWallet,
  onChangeInMoney,
  onChangeCloseZero,
}) => {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [open, setOpen] = useState(false);

  const wrapperRef = useRef();
  const menuRef = useRef();
  const activeLinkRef = useRef();

  useEffect(() => {
    setActiveTab(tabs[cryptoWallets.includes(userActiveWallet.type) ? 0 : 1]);
  }, [userActiveWallet]);

  useMenuAnimation(menuRef, activeLinkRef, activeTab);
  useOutsideClickHandler(wrapperRef, () => setOpen(false));

  return (
    <div className="relative flex items-center" ref={wrapperRef}>
      <button
        type="button"
        className="btn btn--md lg:btn--rounded lg:border-2 lg:border-primary-dark lg:hover:border-[#3d2a59] mr-5 lg:mr-0 pl-0 lg:pl-14 pr-35 dropdown-toggle-arrow dropdown-toggle-arrow--white"
        aria-expanded={open}
        onClick={() => setOpen(!open)}
      >
        <span>
          <WalletMoney wallet={userActiveWallet} rate={currencies[userActiveWallet.type] || 1} inMoney={inMoney} />
        </span>
        <WalletImage
          type={userActiveWallet.type}
          className="w-16 h-16 object-contain object-center ml-10"
        />
      </button>
      <button className="btn btn--fab btn--md btn--green flex-shrink-0 lg:hidden">+</button>
      <div
        className={`dropdown-menu dropdown-menu-end w-[19rem] px-15 pb-13 ${open ? "" : "hidden"}`}
        style={{
          width: "19rem",
        }}
      >
        <nav className="secondary-nav secondary-nav--has-marker secondary-nav--marker-bottom secondary-nav--tiny secondary-nav--dark mb-16">
          <ul ref={menuRef}>
            {tabs.map(tab => {
              const isActive = activeTab ? tab.id === activeTab.id : false;

              return (
                <li key={tab.id}>
                  <button
                    onClick={() => setActiveTab(tab)}
                    type="button"
                    className={`secondary-nav__link ${isActive ? "active" : ""}`}
                    ref={isActive ? activeLinkRef : null}
                  >
                    {t(`wallet.tab.${tab.id}`)}
                  </button>
                </li>
              );
            })}
          </ul>
        </nav>

        <div>
          <ul className="mb-18 -mx-15">
            {activeTab &&
              activeTab.wallets.map(type => {
                const userWallet = userWallets.find(wallet => wallet.type === type);
                const isActive = userActiveWallet.type === type;
                const rate = currencies[type] || 1;
                const wallet = userWallet || { type, balance: 0 };

                if (closeZero && wallet.balance <= 0) {
                  return <></>;
                }

                return (
                  <li key={type}>
                    <button
                      type="button"
                      className={`btn-base w-full flex items-center gap-9 py-6 px-15 hover:bg-[#f3f2f2] transition-all border-l-2 ${
                        isActive ? "border-primary" : "hover:opacity-100 border-gray/50 opacity-60"
                      }`}
                      onClick={() => onSelectActiveWallet(wallet)}
                    >
                      <WalletImage type={type} className="w-16 h-16 object-contain object-center" />
                      <div>{t(`wallet.currency.${type}`)}</div>
                      <div className="ml-auto">
                        <WalletMoney wallet={wallet} rate={rate} inMoney={inMoney} />
                      </div>
                    </button>
                  </li>
                );
              })}
          </ul>

          <div className="space-y-10">
            <label className="switcher-wrapper" htmlFor="wallet-in-money">
              <div className="switcher switcher--sm">
                <input
                  id="wallet-in-money"
                  type="checkbox"
                  className="sr-only"
                  checked={inMoney}
                  onChange={e => onChangeInMoney(e.target.checked)}
                  onBlur={e => onChangeInMoney(e.target.checked)}
                />
                <div className="switcher__content" />
              </div>
              <div className="switcher-wrapper__label pl-10 before:hidden">
                {t("wallet.inMoney")}
              </div>
            </label>
            <label className="switcher-wrapper" htmlFor="wallet-close-zero">
              <div className="switcher switcher--sm">
                <input
                  id="wallet-close-zero"
                  type="checkbox"
                  className="sr-only"
                  checked={closeZero}
                  onChange={e => onChangeCloseZero(e.target.checked)}
                  onBlur={e => onChangeCloseZero(e.target.checked)}
                />
                <div className="switcher__content" />
              </div>
              <div className="switcher-wrapper__label pl-10 before:hidden">
                {t("wallet.closeZero")}
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

const walletProps = PropTypes.shape({
  id: PropTypes.number.isRequired,
  type: PropTypes.number.isRequired,
  balance: PropTypes.number.isRequired,
});

Wallet.propTypes = {
  currencies: PropTypes.shape({}).isRequired,
  userWallets: PropTypes.arrayOf(walletProps).isRequired,
  userActiveWallet: walletProps.isRequired,
  inMoney: PropTypes.bool.isRequired,
  closeZero: PropTypes.bool.isRequired,
  onSelectActiveWallet: PropTypes.func.isRequired,
  onChangeInMoney: PropTypes.func.isRequired,
  onChangeCloseZero: PropTypes.func.isRequired,
};

export default Wallet;
