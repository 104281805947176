import {
  SET_CRASH_STATE,
  SET_CRASH_BET,
  SET_CRASH_HISTORY,
  ADD_CRASH_HISTORY,
  SET_CRASH_BETS,
  SET_CRASH_STATUS,
  SET_CRASH_DELTA,
  SET_CRASH_CRASH,
  SET_CRASH_STATISTIC,
  NEW_CRASH_BET,
  UPDATE_CRASH_BET,
  CRASH_CRASH_BETS,
  UPDATE_USER_CRASH_BET,
  SET_CRASH_HASH,
  SET_CRASH_COEFFICIENT,
  SET_CRASH_AUTO_COEFFICIENT,
  SET_CRASH_AUTO_COEFFICIENT_SETTINGS,
  SET_LOCK_MANUAL_BET,
} from "@/constants";

export const setState = state => {
  return {
    type: SET_CRASH_STATE,
    payload: state,
  };
};

export const setBet = bet => {
  return {
    type: SET_CRASH_BET,
    payload: bet,
  };
};

export const setStatus = status => {
  return {
    type: SET_CRASH_STATUS,
    payload: status,
  };
};

export const setHistory = history => {
  return {
    type: SET_CRASH_HISTORY,
    payload: history,
  };
};


export const addHistory = game => {
  return {
    type: ADD_CRASH_HISTORY,
    payload: game,
  };
};

export const setBets = bets => {
  return {
    type: SET_CRASH_BETS,
    payload: bets,
  };
};

export const setStatistic = statistic => {
  return {
    type: SET_CRASH_STATISTIC,
    payload: statistic,
  };
};

export const setCrash = crash => {
  return {
    type: SET_CRASH_CRASH,
    payload: crash,
  };
};

export const setHash = hash => {
  return {
    type: SET_CRASH_HASH,
    payload: hash,
  };
};

export const setDelta = delta => {
  return {
    type: SET_CRASH_DELTA,
    payload: delta,
  };
};

export const newBet = bet => {
  return {
    type: NEW_CRASH_BET,
    payload: bet,
  };
};

export const updateBet = bet => {
  return {
    type: UPDATE_CRASH_BET,
    payload: bet,
  };
};

export const updateUserBet = bet => {
  return {
    type: UPDATE_USER_CRASH_BET,
    payload: bet,
  };
};

export const crashBets = coefficient => {
  return {
    type: CRASH_CRASH_BETS,
    payload: coefficient,
  };
};


export const setCoefficient = coefficient => {
  return {
    type: SET_CRASH_COEFFICIENT,
    payload: coefficient,
  };
};

export const setAutoCoefficientSettings = settings => {
  return {
    type: SET_CRASH_AUTO_COEFFICIENT_SETTINGS,
    payload: settings,
  };
};

export const setAutoCoefficient = coefficient => {
  return {
    type: SET_CRASH_AUTO_COEFFICIENT,
    payload: coefficient,
  };
};

export const setLockManualBet = lock => {
  return {
    type: SET_LOCK_MANUAL_BET,
    payload: lock,
  };
};