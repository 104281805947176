import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import CrashChart from "@/components/games/crash/CrashChart";
import { BET_WITHDRAW_STATUS } from "@/constants/crashBetStatuses";
import { setCoefficient } from "@/actions/crashAction";
import { WALLET_USDT_TYPE } from "@/constants/walletTypes";

const GameChartContainer = ({ mainRef }) => {
  const dispatch = useDispatch();
  const isMobile = useSelector(({ main }) => main.isMobile);
  const currencies = useSelector(({ main }) => main.currencies);
  const inMoney = useSelector(({ auth }) => auth.walletSettings.inMoney);
  const fullScreen = useSelector(({ main }) => main.fullScreen);
  const fullSize = useSelector(({ main }) => main.fullSize);
  const status = useSelector(({ crash }) => crash.status);
  const delta = useSelector(({ crash }) => crash.delta);
  const crash = useSelector(({ crash }) => crash.crash);
  const bets = useSelector(({ crash }) => crash.bets);
  const bet = useSelector(({ crash }) => crash.bet);

  const isDemo = !!bet && bet.deposit === 0;

  useEffect(() => {
    if (!mainRef.current) {
      return;
    }

    const className = "game-wrapper--green-light";

    if (isDemo) {
      mainRef.current.classList.add(className);
    } else {
      mainRef.current.classList.remove(className);
    }
  }, [isDemo, mainRef]);

  const withdrawBets = bets
    .filter(({ status }) => status === BET_WITHDRAW_STATUS)
    .map(bet => {
      let { withdraw } = bet;

      const precision = bet.walletType === WALLET_USDT_TYPE || inMoney ? 2 : 8;

      if (inMoney) {
        withdraw *= currencies[bet.walletType];
      }

      return {
        ...bet,
        withdraw: Number(withdraw.toFixed(precision)),
      };
    });

  const changeCoefficient = coefficient => {
    window.coefficient = coefficient;
    dispatch(setCoefficient(coefficient));
  };

  return (
    <CrashChart
      isMobile={isMobile}
      isDemo={isDemo}
      status={status}
      delta={delta}
      crash={crash}
      bets={withdrawBets}
      onChangeCoefficient={changeCoefficient}
      isResize={fullSize || fullScreen}
    />
  );
};

GameChartContainer.propTypes = {
  mainRef: PropTypes.shape({}).isRequired,
};

export default GameChartContainer;
