import PropTypes from "prop-types";

export default PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  entity: PropTypes.string.isRequired,
  qualityId: PropTypes.number,
  description: PropTypes.string,
  price: PropTypes.number.isRequired,
  colorId: PropTypes.number.isRequired,
});