import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSubscribe } from "@/libs/centrifuge";
import useSound from "@/helpers/useSound";
import { setRound, setBet, setUser, setRoomUsersCount, addLuckyWin } from "@/actions/diceAction";
import {
  DICE_ROUND_BET_ROLLING_STATUS,
  DICE_ROUND_BET_WAITING_STATUS,
} from "@/constants/diceRoundBetStatuses";
import { DICE_ROUND_FINISH_STATUS, DICE_ROUND_WAITING_STATUS } from "@/constants/diceRoundStatuses";

const DiceRoomSubscriberContainer = () => {
  const roomId = useSelector(({ dice }) => (dice.activeRoom ? dice.activeRoom.id : null));
  const sound = useSound();
  const subscribe = useSubscribe();
  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = subscribe(`dice-${roomId}`, ({ type, ...payload }) => {
      switch (type) {
        case "user": {
          dispatch(setUser(payload));
          break;
        }
        case "round": {
          const { round } = payload;

          if (round.status === DICE_ROUND_WAITING_STATUS) {
            sound.diceStart();
          }

          if (round.status === DICE_ROUND_FINISH_STATUS) {
            sound.diceEnd();
          }

          dispatch(setRound(round));
          break;
        }
        case "bet": {
          const { bet } = payload;
          if (bet.status === DICE_ROUND_BET_WAITING_STATUS) {
            sound.bet();
          }

          if (bet.status === DICE_ROUND_BET_ROLLING_STATUS) {
            sound.diceRoll();
          }

          dispatch(setBet(bet));
          break;
        }

        default:
          break;
      }
    });

    return () => {
      unsubscribe();
    };
  }, [roomId, sound]);

  useEffect(() => {
    const unsubscribeRoomUsersCount = subscribe("dice", ({ type, ...payload }) => {
      switch (type) {
        case "roundUsersCount": {
          dispatch(setRoomUsersCount(payload));
          break;
        }
        case "luckyWins": {
          dispatch(addLuckyWin(payload));
          break;
        }
        default:
          break;
      }
    });

    return () => {
      unsubscribeRoomUsersCount();
    };
  });

  return <></>;
};

export default DiceRoomSubscriberContainer;
