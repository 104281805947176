import { WALLET_BTC_TYPE, WALLET_ETH_TYPE, WALLET_USDT_TYPE } from "@/constants/walletTypes";

export default {
  tab: {
    crypto: "Crypto",
    stable: "Stable",
  },
  currency: {
    [WALLET_BTC_TYPE]: "BTC",
    [WALLET_ETH_TYPE]: "ETH",
    [WALLET_USDT_TYPE]: "USDT",
  },
  names: {
    [WALLET_BTC_TYPE]: "BitCoin",
    [WALLET_ETH_TYPE]: "Etherium",
  },
  deposit: {
    address: "Your Address",
    description: {
      [WALLET_BTC_TYPE]: "Send Amount of BTC to the following address. We do not accept BEP20 from Binance.",
      [WALLET_ETH_TYPE]: "Send Amount of ETH to the following address. We do not accept BEP20 from Binance.",
    },
    copy: "Copy address",
    errorLoad: "Error loading wallet. Try again."
  },
  inMoney: "In money",
  closeZero: "Close zero",
};
