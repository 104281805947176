import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import useOutsideClickHandler from "@/helpers/useOutsideClickHandler";
import UserMenuLink from "./UserMenuLink";

const UserMenu = ({ user, links }) => {
  const [open, setOpen] = useState(false);
  const wrapperRef = useRef(null);
  const { t } = useTranslation();

  const close = () => setOpen(false);

  useOutsideClickHandler(wrapperRef, close);

  return (
    <div ref={wrapperRef} className="relative hidden lg:block">
      <button
        className="btn btn--white btn--text btn--rounded btn--md btn--alpha-bg dropdown-toggle-arrow"
        onClick={() => setOpen(!open)}
        type="button"
      >
        <span className="text-white">{user.name}</span>
      </button>
      <ul className={`dropdown-menu dropdown-menu-end ${open ? "" : "hidden"}`}>
        {links.map(link => (
          <li key={link.id} onClick={() => setOpen(false)}>
            <UserMenuLink link={link} className="dropdown-item btn btn--tile justify-between w-150">
              {t(`userMenu.${link.id}`)}
              {link.icon}
            </UserMenuLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

UserMenu.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default UserMenu;
