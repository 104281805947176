import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import InputDeposit from "@/components/games/InputDeposit";
import WalletPrefix from "@/components/Wallet/WalletPrefix";
import getToFixed from "@/components/Wallet/getToFixed";

const CoinflipCreateForm = ({ onSubmit, walletType }) => {
  const { t } = useTranslation();

  const [deposit, setDeposit] = useState(Number(0).toFixed(getToFixed(walletType)));

  return (
    <div>
      <InputDeposit
        value={deposit}
        onChange={value => {
          setDeposit(value);
        }}
        walletType={walletType}
      />

      <button
        type="button"
        className="btn btn--md btn--rounded btn--primary w-full justify-between mt-25"
        onClick={() => onSubmit(deposit)}
      >
        <span>{t("coinflip.create")}</span>
        <span>
          <WalletPrefix type={walletType} /> {Number(deposit).toFixed(getToFixed(walletType))}
        </span>
      </button>
    </div>
  );
};

CoinflipCreateForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  walletType: PropTypes.number.isRequired,
};

export default CoinflipCreateForm;
