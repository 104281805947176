import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import InputDeposit from "@/components/games/InputDeposit";
import getToFixed from "@/components/Wallet/getToFixed";

const RouletteControl = ({ onPlaceBet, walletType }) => {
  const [deposit, setDeposit] = useState(Number(0).toFixed(getToFixed(walletType)));
  const { t } = useTranslation();

  return (
    <div>
      <InputDeposit
        value={deposit}
        onChange={newDeposit => setDeposit(newDeposit)}
        walletType={walletType}
      />

      <div className="space-y-10">
        {[
          {
            id: 3,
            image: "gold",
            coefficient: 14,
          },
          {
            id: 2,
            image: "silver",
            coefficient: 2,
          },
          {
            id: 1,
            image: "bronze",
            coefficient: 2,
          },
        ].map(({ id, image, coefficient }) => (
          <button
            key={id}
            type="button"
            onClick={() => {
              onPlaceBet({ deposit, number: id });
            }}
            className="btn btn--light-black hover:bg-black-660 py-10 px-15 h-55 w-full"
          >
            <img
              src={`/img/${image}.png`}
              alt=""
              className="w-35 h-35 rounded-full object-contain mr-10"
            />
            <span>X{coefficient}</span>
            <span className="text-white ml-auto">{t("roulette.placeBet")}</span>
          </button>
        ))}
      </div>
    </div>
  );
};

RouletteControl.propTypes = {
  onPlaceBet: PropTypes.func.isRequired,
  walletType: PropTypes.number.isRequired,
};

export default RouletteControl;
