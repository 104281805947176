import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const DepositButton = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex items-center lg:hidden">
        <button type="button" className="btn btn--fab btn--md btn--green" onClick={onClick}>
          +
        </button>
      </div>
      <button
        type="button"
        className="btn btn--md btn--primary btn--rounded hidden lg:flex"
        onClick={onClick}
      >
        {t("depositButton")}
      </button>
    </>
  );
};

DepositButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default DepositButton;
