import React from "react";
import useScrollTop from "@/helpers/useScrollTop";

const TosPage = () => {

  useScrollTop();

  return (
    <div className="bg-black rounded">

    </div>
  );
};

export default TosPage;