import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import noty from "@/libs/noty";
import { useApi } from "@/api";
import useSound from "@/helpers/useSound";
import RouletteControl from "@/components/games/roulette/RouletteControl";
import { WALLET_USDT_TYPE } from "@/constants/walletTypes";

const RouletteControlContainer = () => {
  const { t } = useTranslation();
  const { rouletteMake } = useApi();
  const sound = useSound();

  const user = useSelector(({ auth }) => auth.user);
  const currencies = useSelector(({ main }) => main.currencies);
  const inMoney = useSelector(({ auth }) => auth.walletSettings.inMoney);

  const walletType = user && user.activeWallet ? user.activeWallet.type : WALLET_USDT_TYPE;

  const placeBet = data => {
    if (!user) {
      noty.error(t("notAuth"));
      return;
    }

    let deposit = Number(data.deposit);

    if (inMoney) {
      deposit /= currencies[walletType];
    }

    if (user.activeWallet.balance < deposit) {
      noty.error(t("notEnoughBalance"));
      return;
    }

    rouletteMake({ deposit, number: data.number })
      .then(() => {
        sound.bet();
        noty.success(t("roulette.success"));
      })
      .catch(error => {
        if (error.response.status === 403) {
          if (error.response.data.error) {
            noty.error(t(`roulette.error.${error.response.data.error}`));
          }
        }
      });
  };

  return <RouletteControl onPlaceBet={placeBet} walletType={inMoney ? WALLET_USDT_TYPE : walletType} />;
};

export default RouletteControlContainer;
