import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSubscribe } from "@/libs/centrifuge";
import { setCurrencies } from "@/actions/mainAction";

const CurrenciesSubscriberContainer = () => {
  const dispatch = useDispatch();
  const subscribe = useSubscribe();

  useEffect(() => {
    const unsubscribe = subscribe("currencies", ({ currencies }) => {
      dispatch(setCurrencies(currencies));
    });

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  return <></>;
};

export default CurrenciesSubscriberContainer;
