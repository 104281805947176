import React from "react";
import PropTypes from "prop-types";

const GameFooter = ({ children, className, onClickFullScreen, onClickFullSize }) => {
  return (
    <div className={`game-footer ${className}`}>
      <div className="flex-shrink-0">
        <div className="hidden lg:flex space-x-10">
          <button
            type="button"
            className="btn btn--sm btn--black btn--fab"
            onClick={onClickFullSize}
          >
            <svg aria-hidden="true" className="fill-current w-24 h-24" id="fullsize-toggler">
              <use href="/svg/svg.svg#fullsize" />
            </svg>
          </button>
          <button
            type="button"
            className="btn btn--sm btn--black btn--fab"
            id="fullscreen-toggler"
            onClick={onClickFullScreen}
          >
            <svg aria-hidden="true" className="fill-current w-22 h-22">
              <use href="/svg/svg.svg#fullscreen" />
            </svg>
          </button>
        </div>
      </div>
      {children}
    </div>
  );
};

GameFooter.propTypes = {
  children: PropTypes.node.isRequired,
  onClickFullScreen: PropTypes.func.isRequired,
  onClickFullSize: PropTypes.func.isRequired,
  className: PropTypes.string,
};

GameFooter.defaultProps = {
  className: "",
};

export default GameFooter;
