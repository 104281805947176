import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import config from "@/config";
import itemType from "./itemType";

const colors = {
  1: "#4b69ff",
  2: "#5e98d9",
  3: "#8847ff",
  4: "#b0c3d9",
  5: "#d32ce6",
  6: "#e4ae39",
  7: "#eb4b4b",
};

const WithdrawSkinItems = ({ items, loadMore, hasMore, selectedItem, onSelectItem }) => {
  const { t } = useTranslation();

  return (
    <>
      <ul className="payment-methods-skins">
        {items.map(item => {
          const { id, entity, description, qualityId, price, colorId } = item;
          return (
            <li key={id}>
              <button
                type="button"
                className={`drop-preview ${
                  selectedItem && selectedItem.id === id ? "selected" : ""
                }`}
                onClick={() => onSelectItem(item)}
                style={{ color: colors[colorId] }}
              >
                <div className="drop-preview__photo">
                  <img src={`${config.cdnUrl}items/${id}/medium.png`} alt="" />
                </div>
                <div className="drop-preview__title">{entity}</div>
                <div className="drop-preview__subtitle">{description}</div>
                <div className="drop-preview__desc">
                  {qualityId ? t(`market.qualities.${qualityId}`) : ""}
                </div>
                <div className="drop-preview__price">$ {price.toFixed(2)}</div>
              </button>
            </li>
          );
        })}
      </ul>
      {hasMore && (
        <div className="mt-10 text-right">
          <button
            type="button"
            className="btn btn--sm btn--rounded btn--gray"
            onClick={() => loadMore()}
          >
            {t("withdraw.loadMore")}
          </button>
        </div>
      )}
    </>
  );
};

WithdrawSkinItems.propTypes = {
  items: PropTypes.arrayOf(itemType).isRequired,
  selectedItem: itemType,
  onSelectItem: PropTypes.func.isRequired,
  loadMore: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
};


WithdrawSkinItems.defaultProps = {
  selectedItem: null,
};

export default WithdrawSkinItems;
