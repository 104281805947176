import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setActiveRound } from "@/actions/coinflipAction";
import { useApi } from "@/api";
import CoinflipRound from "@/components/games/coinflip/CoinflipRound";

const CoinflipRoundContainer = () => {
  const activeRound = useSelector(({ coinflip }) => coinflip.activeRound);
  const dispatch = useDispatch();
  const { coinflipRound } = useApi();

  useEffect(() => {
    if (activeRound) {
      coinflipRound(activeRound.id)
        .then(data => {
          dispatch(setActiveRound(data));
        })
        .catch(() => {
          console.log(`Fail load round #${activeRound.id}`);
        });
    }
  }, []);

  return <CoinflipRound round={activeRound} onClickBack={() => dispatch(setActiveRound(null))} />;
};

export default CoinflipRoundContainer;
