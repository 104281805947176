export default {
  title: "Crash game",
  countdown: {
    title: "Preparing round",
    desc: "Starting in:",
  },
  progress: {
    desc: "Current Payout",
  },
  finish: {
    desc: "The round is over",
  },
  create: {
    amount: "Bet amount",
    autoCoefficient: "Auto Cashout",
    selectAutoCoefficient: "Choose Auto-Cashout",
    button: "Place Bet",
    demo: "Betting $0.00 will enter demo mode",
    starting: "Waiting",
    success: "You have successfully placed your bid",
    error: "Failed to place a bet",
    cancel: "Cancel bet",
    playDemo: "Play Demo",
  },
  controlTab: {
    manual: "Manual",
    auto: "Autobet",
    errors: {
      autoBetEnabled: "You have active auto betting",
    },
  },
  upgrade: {
    button: "Upgrade",
    success: "Success upgrade",
    error: "Failed upgrade",
  },
  stats: {
    count: "Players",
  },
  history: "History",
  autobet: {
    amount: "Bet amount",
    autoCoefficient: "Auto Cashout",
    count: "Total Bets",
    onWin: "On Win",
    reset: "Reset",
    increase: "Increase",
    start: "Start AutoBet",
    stop: "Stop AutoBet",
    onLose: "On Lose",
    stopOnProfit: "Stop On Profit",
    stopOnLose: "Stop On Lose",
    errors: {
      deposit: "Bet amount is required!",
      autoCoefficient: "Auto Cashout field is required!",
    },
  },
  demo: {
    title: "Demo Mode",
    desc: "You play demo game",
  }
};
