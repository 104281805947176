import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { BET_WAITING_STATUS, BET_WITHDRAW_STATUS, BET_CRASHED_STATUS } from "@/constants/crashBetStatuses";
import WalletPrefix from "@/components/Wallet/WalletPrefix";
import getToFixed from "@/components/Wallet/getToFixed";
import { WALLET_USDT_TYPE } from "@/constants/walletTypes";

const CrashBets = ({ bets, inMoney, currencies }) => {
  const { t } = useTranslation();

  return (
    <ul
      className="overflow-auto focus-outline-inset disable-scrollbars lg:max-h-full"
      style={{ height: "24rem" }}
    >
      {bets.map(bet => {
        const { id, user, status, coefficient, walletType } = bet;

        const deposit = inMoney ? bet.deposit * (currencies[walletType] || 1) : bet.deposit;
        const withdraw = inMoney ? bet.withdraw * (currencies[walletType] || 1) : bet.withdraw;

        let classNameSum = "";
        let classCurrency = "";

        if (status === BET_WITHDRAW_STATUS) {
          classNameSum = "text-green-200";
          classCurrency = "bg-green";
        }
        if (status === BET_CRASHED_STATUS) {
          classNameSum = "text-red";
          classCurrency = "bg-red";
        }

        return (
          <li key={id} className="flex items-center justify-between px-20 py-5 odd:bg-black-600 relative z-1">
            {status !== BET_WAITING_STATUS && <div
              className={`absolute inset-y-0 right-0 w-150 bg-gradient-to-l from-${
                status === BET_WITHDRAW_STATUS ? "green" : "red"
              } opacity-15 -z-1`}
            />}
            <div className={!user.name ? "text-gray-200" : ""}>
              {status === BET_WITHDRAW_STATUS && (
                <span className="text-green-200 mr-5">{coefficient.toFixed(2)}x</span>
              )}
              {user.name || t("hidden")}
            </div>
            <div className="flex items-center">
              <span className={classNameSum}>
                {status === BET_WITHDRAW_STATUS
                  ? `+${withdraw.toFixed(getToFixed(walletType, inMoney))}`
                  : deposit.toFixed(getToFixed(walletType, inMoney))}
              </span>
              <div className={`currency-symbol ml-7 ${classCurrency}`}>
                <WalletPrefix type={inMoney ? WALLET_USDT_TYPE : walletType} />
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

CrashBets.propTypes = {
  bets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      user: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string,
      }).isRequired,
      walletType: PropTypes.number.isRequired,
      deposit: PropTypes.number.isRequired,
      withdraw: PropTypes.number,
      coefficient: PropTypes.number,
      status: PropTypes.number.isRequired,
    }),
  ).isRequired,
  inMoney: PropTypes.bool.isRequired,
  currencies: PropTypes.shape({}).isRequired,
};

export default CrashBets;
