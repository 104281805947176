import auth from "./auth/en";
import form from "./form/en";
import mines from "./mines/en";
import crash from "./crash/en";
import towers from "./towers/en";
import footer from "./footer/en";
import userMenu from "./userMenu/en";
import profile from "./profile/en";
import roulette from "./roulette/en";
import dice from "./dice/en";
import fair from "./fair/en";
import live from "./live/en";
import notifications from "./notifications/en";
import referral from "./referral/en";
import chat from "./chat/en";
import withdraw from "./withdraw/en";
import coinflip from "./coinflip/en";
import wallet from "./wallet/en";

export default {
  translation: {
    hidden: "[hide]",
    main: {
      menu: {
        promo: "Promo",
        referral: "Affiliate",
      },
    },
    navigation: {
      main: "Main",
      fair: "Fair Play",
      support: "Support",
      cooperation: "Cooperation",
    },
    gameList: {
      mines: "Mines",
      crash: "Crash",
      roulette: "Roulette",
      dice: "Dice",
      towers: "Towers",
      coinflip: "Coinflip",
      baccarat: "Baccarat",
      blackjack: "Blackjack",
      gameshow: "Game Show",
      slots: "Slots",
      includes: "Includes - {{ count }} games",
    },
    notEnoughBalance: "Insufficient funds.",
    inputDeposit: "Bet amount",
    depositButton: "Deposit",
    deposit: {
      tab: {
        crypto: "Crypto",
        money: "Money",
        skins: "Skins",
      },
    },
    cashier: {
      title: "Cashier",
      balance: "Your balance",
      links: {
        deposit: "Deposit",
        withdraw: "Withdraw",
        tip: "Tip",
      },
      deposit: {
        enterAmount: "Enter the amount",
        button: "Deposit",
      },
    },
    maxProfit: "Max profit",
    fileSelector: {
      chooseFile: "Select a file",
      button: "Upload",
      bigFile: "The file is too large. The maximum size is 2MB.",
    },
    networkError: "Network Error",
    systemLockedError: "Locked",
    tip: {
      username: "Username",
      usernamePlaceholder: "Nickname user",
      amount: "Amount",
      amountPlaceholder: "$ 0.00",
      amountDesc: "Your balance remaining after tip must be {{ minimumBalance }} or greater.",
      private: "Private",
      privateTitle: "PrivateTransfer",
      privateDesc: "Private tips will not be displayed publicly in the chat",
      submit: "Send Tip",
      success: "Success send tip",
      fail: "Fail send tip",
      USER_NOT_FOUND: "That user does not exist.",
      NOT_HAVE_MINIMUM_BALANCE:
        "To give a tip you must have {{ minimumBalance }} leftover balance.",
    },
    vip: {
      title: "Elite Players Only",
      subtitle: "The only club you want to be apart of.",
      content: `<p>Hey there!</p>
          <p class="text-gray-200">
            Our VIP Club is where a distinguished group of elite-players come to
            enjoy personal perks and rewards. Your very own personal VIP manager
            will reach out to you the moment you qualify and welcome you into the
            club. From here you'll have 24/7 access to a dedicated VIP manager and
            be showered in rewards. Until then, hop into our chat, be active in
            the community, and have fun playing on MIDASLY.
          </p>`,
    },
    luckyWins: "Lucky wins",
    footer,
    userMenu,
    auth,
    form,
    mines,
    crash,
    towers,
    profile,
    roulette,
    dice,
    fair,
    live,
    notifications,
    referral,
    chat,
    withdraw,
    coinflip,
    wallet,
  },
};
