import {
  SET_SOUNDS_ON,
  SET_CURRENCY,
  SET_CENTRIFUGE_TOKEN,
  SET_IS_MOBILE,
  SET_TOTAL_WAGER,
  SET_FULL_SIZE,
  SET_FULL_SCREEN,
  SET_OPEN_CASHIER,
  SET_PAYMENT_METHODS,
  SET_OPEN_REFERRAL,
  SET_OPEN_AUTH,
  SET_CURRENCIES,
  SET_IS_GAME_PAGE,
} from "@/constants";

export const setCentrifugeToken = token => {
  return {
    type: SET_CENTRIFUGE_TOKEN,
    payload: token,
  };
};

export const setCurrency = currency => {
  return {
    type: SET_CURRENCY,
    payload: currency,
  };
};

export const setCurrencies = currencies => {
  return {
    type: SET_CURRENCIES,
    payload: currencies,
  };
};

export const setSoundsOn = soundsOn => {
  return {
    type: SET_SOUNDS_ON,
    payload: soundsOn,
  };
};

export const setIsMobile = isMobile => {
  return {
    type: SET_IS_MOBILE,
    payload: isMobile,
  };
};

export const setTotalWager = totalWager => {
  return {
    type: SET_TOTAL_WAGER,
    payload: totalWager,
  };
};

export const setIsGamePage = isGamePage => {
  return {
    type: SET_IS_GAME_PAGE,
    payload: isGamePage,
  };
};

export const setFullSize = fullSize => {
  return {
    type: SET_FULL_SIZE,
    payload: fullSize,
  };
};

export const setFullScreen = fullScreen => {
  return {
    type: SET_FULL_SCREEN,
    payload: fullScreen,
  };
};

export const setOpenCashier = openCashier => {
  return {
    type: SET_OPEN_CASHIER,
    payload: openCashier,
  };
};

export const setOpenReferral = openReferral => {
  return {
    type: SET_OPEN_REFERRAL,
    payload: openReferral,
  };
};

export const setOpenAuth = openAuth => {
  return {
    type: SET_OPEN_AUTH,
    payload: openAuth,
  };
};

export const setPaymentMethods = paymentMethods => {
  return {
    type: SET_PAYMENT_METHODS,
    payload: paymentMethods,
  };
};
