import React from "react";
import { useSelector } from "react-redux";

import RouletteBets from "@/components/games/roulette/RouletteBets";

const RouletteBetsContainer = () => {
  const bets = useSelector(({ roulette }) => roulette.bets);
  const inMoney = useSelector(({ auth }) => auth.walletSettings.inMoney);
  const currencies = useSelector(({ main }) => main.currencies);

  return <RouletteBets bets={bets} inMoney={inMoney} currencies={currencies} />;
};

export default RouletteBetsContainer;
