import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const WithdrawTradeLink = ({ steamId, tradeUrl, disabled, onSubmit }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(tradeUrl);

  useEffect(() => {
    setValue(tradeUrl);
  }, [tradeUrl]);

  return (
    <label className="field-wrapper" htmlFor="withdraw-trade-link">
      <div className="field-wrapper__title text-black-900">{t("withdraw.tradeUrl.title")}</div>
      <input
        disabled={disabled}
        value={value || ""}
        onChange={e => setValue(e.target.value)}
        type="text"
        className="text-field text-field--light"
        placeholder="https://steamcommunity.com/tradeoffer/"
        id="withdraw-trade-link"
      />
      {steamId && (
        <a
          href={`https://steamcommunity.com/profiles/${steamId}/tradeoffers/privacy`}
          className="field-wrapper__description text-right text-green"
        >
          {t("withdraw.tradeUrl.helpLink")}
        </a>
      )}
      {tradeUrl !== value && (
        <div className="field-wrapper__description text-right">
          <button
            type="button"
            className="btn btn--primary-light btn--text btn--offset-r btn--md"
            onClick={() => onSubmit(value)}
            disabled={disabled}
          >
            {t("withdraw.tradeUrl.submit")}
          </button>
        </div>
      )}
    </label>
  );
};

WithdrawTradeLink.propTypes = {
  steamId: PropTypes.string.isRequired,
  tradeUrl: PropTypes.string,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

WithdrawTradeLink.defaultProps = {
  tradeUrl: null,
  disabled: false,
};

export default WithdrawTradeLink;
