import PropTypes from "prop-types";

export default {
  crash: PropTypes.number,
  delta: PropTypes.number,
  status: PropTypes.number,
  bets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      withdraw: PropTypes.shape({
        amount: PropTypes.number.isRequired,
      }).isRequired,
      coefficient: PropTypes.number.isRequired,
    }),
  ).isRequired,
  isResize: PropTypes.bool.isRequired,
  isDemo: PropTypes.bool.isRequired,
};
