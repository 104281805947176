import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Bets from "@/components/games/Bets";

const CoinflipLuckyWins = ({ bets }) => {
  const { t } = useTranslation();

  return (
    <div className="pt-25 -mx-20 overflow-hidden flex flex-col mt-8 max-h-300">
      <div className="flex items-center text-gray-600 mb-10 px-20">
        <svg aria-hidden="true" className="fill-current text-green w-30 h-25 mr-5 -ml-5 -mt-2">
          <use href="/svg/svg.svg#crown" />
        </svg>
        <span>{t("coinflip.luckyWins")}</span>
      </div>

      <Bets bets={bets} />
    </div>
  );
};

CoinflipLuckyWins.propTypes = {
  bets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      coefficient: PropTypes.number.isRequired,
      deposit: PropTypes.number.isRequired,
      withdraw: PropTypes.number.isRequired,
      status: PropTypes.bool.isRequired,
      userName: PropTypes.string,
    }),
  ).isRequired,
};

export default CoinflipLuckyWins;
