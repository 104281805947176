import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useMenuAnimation from "@/helpers/useMenuAnimation";
import noty from "@/libs/noty";
import CrashControlContainer from "@/containers/games/crash/CrashControlContainer";
import CrashStatsContainer from "@/containers/games/crash/CrashStatsContainer";
import CrashBetsContainer from "@/containers/games/crash/CrashBetsContainer";
import CrashAutoBetContainer from "@/containers/games/crash/CrashAutoBetContainer";

const CrashSidebarContainer = ({ mainRef }) => {
  const { t } = useTranslation();
  const lockManualBet = useSelector(({ crash }) => crash.lockManualBet);


  const tabs = [
    {
      id: "manual",
      content: (
        <>
          <CrashControlContainer mainRef={mainRef} />
          <div className="pt-25 -mx-20 overflow-hidden flex flex-col mt-8 max-h-300">
            <CrashStatsContainer />
            <CrashBetsContainer />
          </div>
        </>
      ),
      disabled: lockManualBet,
      disabledError: "autoBetEnabled",
    },
    {
      id: "auto",
      content: <CrashAutoBetContainer />,
    },
  ];

  const [activeTab, setActiveTab] = useState(tabs[0]);

  const menuRef = useRef();
  const activeLinkRef = useRef();

  useMenuAnimation(menuRef, activeLinkRef, activeTab);

  return (
    <div className="game-wrapper__sidebar">
      <div className="game-sidebar-inner">
        <div className="contents">
          <nav className="secondary-nav secondary-nav--has-marker secondary-nav--marker-bottom secondary-nav--narrow game-sidebar-nav">
            <ul className="justify-around" ref={menuRef}>
              {tabs.map(tab => {
                const isActive = tab.id === activeTab.id;

                return (
                  <li key={tab.id}>
                    <button
                      type="button"
                      className={`secondary-nav__link ${isActive ? "active" : ""}`}
                      onClick={() => {
                        if(tab.disabled) {
                          noty.error(t(`crash.controlTab.errors.${tab.disabledError}`))
                          return;
                        }

                        setActiveTab(tab)
                      }}
                      ref={isActive ? activeLinkRef : null}
                    >
                      {t(`crash.controlTab.${tab.id}`)}
                    </button>
                  </li>
                );
              })}
            </ul>
          </nav>
          {activeTab.content}
        </div>
      </div>
    </div>
  );
};

export default CrashSidebarContainer;
