import React from "react";
import PropTypes from "prop-types";
import { WALLET_BTC_TYPE } from "@/constants/walletTypes";

const images = {
  [WALLET_BTC_TYPE]: "/img/bitcoin.svg",
};

const defaultImage = "/img/bitcoin.svg";

const WalletImage = ({ type, className }) => {
  return <img src={images[type] || defaultImage} className={className} alt="" />;
};

WalletImage.propTypes = {
  type: PropTypes.number.isRequired,
  className: PropTypes.string,
};

WalletImage.defaultProps = {
  className: "",
};

export default WalletImage;
