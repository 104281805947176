import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import InputDeposit from "@/components/games/InputDeposit";
import getToFixed from "@/components/Wallet/getToFixed";
import WalletPrefix from "@/components/Wallet/WalletPrefix";

const TowersControl = ({
  levels,
  isStarting,
  onCashOut,
  onCreate,
  startedDeposit,
  level: activeLevel,
  withdraw,
  onChangeDeposit,
  onChangeLevel,
  walletType,
}) => {
  const { t } = useTranslation();

  const [deposit, setDeposit] = useState(Number(0).toFixed(getToFixed(walletType)));

  const isDemo = Number(deposit) === 0;

  useEffect(() => {
    setDeposit(startedDeposit.toFixed(getToFixed(walletType)));
  }, [startedDeposit]);

  useEffect(() => {
    onChangeDeposit(deposit);
  }, [deposit]);

  return (
    <div>
      <InputDeposit
        value={deposit || ""}
        onChange={setDeposit}
        max={20000}
        walletType={walletType}
      />

      <nav className="secondary-nav secondary-nav--has-marker secondary-nav--marker-bottom secondary-nav--narrow -my-2">
        <ul className="justify-between">
          {levels.map(level => (
            <li key={level}>
              <button
                type="button"
                className={`secondary-nav__link ${activeLevel === level ? "active" : ""}`}
                onClick={() => {
                  if (!isStarting) {
                    onChangeLevel(level);
                  }
                }}
              >
                {t(`towers.levels.${level}`)}
              </button>
            </li>
          ))}
        </ul>
      </nav>
      <div className="h-2 bg-black-600 mb-16" />
      <div className="text-sm text-gray-400 mt-12 flex items-center justify-center">
        {t("towers.create.level")}
      </div>

      <button
        type="button"
        className={`btn btn--md btn--rounded w-full justify-between mt-22 ${
          isStarting ? "btn--green" : "btn--primary"
        }`}
        onClick={() => {
          if (!isStarting) {
            onCreate({ deposit, level: activeLevel });
          } else {
            onCashOut();
          }
        }}
      >
        {!isStarting && isDemo ? (
          t("towers.create.demo")
        ) : (
          <>
            <span>{!isStarting ? t("towers.create.submit") : t("towers.cashOut")}</span>
            <span>
              <WalletPrefix type={walletType} />{" "}
              {Number(!isStarting ? deposit : withdraw).toFixed(getToFixed(walletType))}
            </span>
          </>
        )}
      </button>

      <div className="text-sm text-gray-400 mt-12 flex items-center justify-center">
        <svg aria-hidden="true" className="fill-current w-12 h-12 mr-7">
          <use href="/svg/svg.svg#info" />
        </svg>
        {t("towers.demo")}
      </div>
    </div>
  );
};

TowersControl.propTypes = {
  levels: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  isStarting: PropTypes.bool.isRequired,
  onCreate: PropTypes.func.isRequired,
  onCashOut: PropTypes.func.isRequired,
  withdraw: PropTypes.number,
  startedDeposit: PropTypes.number.isRequired,
  level: PropTypes.number.isRequired,
  walletType: PropTypes.number.isRequired,
  onChangeLevel: PropTypes.func.isRequired,
  onChangeDeposit: PropTypes.func.isRequired,
};

TowersControl.defaultProps = {
  withdraw: null,
};

export default TowersControl;
