import React from "react";
import PropTypes from "prop-types";

const TowersRound = ({ rows, activeRow, onSelect }) => {
  return (
    <div className="game-wrapper__main">
      <div className="lg:h-620 flex flex-col justify-center items-center py-10">
        {rows.map(({ id, isClose, cells }) => {

          const gridClass = `grid grid-cols-${cells.length} gap-20 w-450 max-w-full mx-auto relative`;

          if (activeRow > id || isClose) {
            return (
              <div
                key={id}
                className={`transition-all duration-100 py-8 px-10 w-full border-r-3 border-transparent ${
                  activeRow === id ? "border-primary bg-primary-100 bg-opacity-2" : ""
                }`}
              >
                <div
                  className={gridClass}
                >
                  {cells.map(({ id: cellId, withdraw, isPoop }) => {
                    if (isPoop) {
                      return (
                        <div key={cellId} className="btn btn--light-black btn--lg bg-transparent">
                          {withdraw}
                        </div>
                      );
                    }
                    return (
                      <div
                        key={cellId}
                        className="btn btn--primary btn--lg btn--fab btn--rounded mx-auto"
                      >
                        <svg aria-hidden="true" className="fill-current w-22 h-22">
                          <use href="/svg/svg.svg#star" />
                        </svg>
                      </div>
                    );
                  })}
                  <div className="flex justify-evenly inset-0 absolute top-1/2 -mt-8 -mx-10">
                    <div className="bg-primary-100 bg-opacity-5 h-17 w-2" />
                    <div className="bg-primary-100 bg-opacity-5 h-17 w-2" />
                  </div>
                </div>
              </div>
            );
          }

          if (activeRow === id) {
            return (
              <div
                key={id}
                className="transition-all duration-100 py-8 px-10 w-full border-r-3 border-transparent "
              >
                <div
                  className={gridClass}
                >
                  {cells.map(({ id: cellId, withdraw }) => {
                    return (
                      <button
                        type="button"
                        key={cellId}
                        className="btn btn--lg btn--primary"
                        onClick={() => onSelect(cellId)}
                      >
                        {withdraw}
                      </button>
                    );
                  })}
                </div>
              </div>
            );
          }

          return (
            <div
              key={id}
              className="transition-all duration-100 py-8 px-10 w-full border-r-3 border-transparent"
            >
              <div className={gridClass}>
                {cells.map(({ id: cellId, withdraw }) => {
                  return (
                    <div
                      key={cellId}
                      className="btn btn--light-black btn--lg group-hover:btn--primary"
                    >
                      {withdraw}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

TowersRound.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      cells: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          withdraw: PropTypes.node.isRequired,
        }),
      ),
    }).isRequired,
  ).isRequired,
};

export default TowersRound;
