import React from "react";
import { useSelector } from "react-redux";
import CrashBets from "@/components/games/crash/CrashBets";

const CrashBetsContainer = () => {
  const bets = useSelector(({ crash }) => crash.bets);
  const inMoney = useSelector(({ auth }) => auth.walletSettings.inMoney);
  const currencies = useSelector(({ main }) => main.currencies);

  return <CrashBets bets={bets} inMoney={inMoney} currencies={currencies} />;
};

export default CrashBetsContainer;
