import { useEffect, useState } from "react";
import getMarketItems from "@/libs/market/getMarketItems";

const useMarketItems = ({ pageSize }) => {
  const [filter, setFilter] = useState({});
  const [db, setDb] = useState([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    getMarketItems().then(db => {
      setDb(db);
    });
  }, []);

  const items = db.filter(item => {
    let filtered = true;

    if (filter.minPrice) {
      filtered = item.price >= Number(filter.minPrice);
    }

    if (filtered && filter.maxPrice) {
      filtered = item.price <= Number(filter.maxPrice);
    }

    if (filtered && filter.name) {
      filtered = item.name.toLowerCase().includes(filter.name.toLowerCase());
    }

    if (filtered && filter.quality) {
      filtered = item.qualityId === filter.quality;
    }

    if (filtered && filter.rarity) {
      filtered = item.rarity === filter.rarity;
    }

    if (filtered && filter.type) {
      filtered = item.type === filter.type;
    }

    if (filtered && filter.count) {
      filtered = item.count >= filter.count;
    }

    return filtered;
  });

  const hasMore = items.length > pageSize * page;

  const loadMore = () => {
    if (hasMore) {
      setPage(page + 1);
    }
  };

  useEffect(() => {
    setPage(1);
  }, [filter]);

  return {
    setFilter,
    items: items.slice(0, pageSize * page),
    loadMore,
    hasMore,
    marketDate: localStorage.getItem("market-items-date"),
  };
};

export default useMarketItems;
