import React from "react";
import PropTypes from "prop-types";

const WalletMoney = ({ wallet, rate, inMoney }) => {
  return (
    <>
      {inMoney
        ? `$${(wallet.balance * rate).toFixed(2)}`
        : wallet.balance.toFixed(wallet.toFixed || 8)}
    </>
  );
};

WalletMoney.propTypes = {
  wallet: PropTypes.shape({
    type: PropTypes.number.isRequired,
    balance: PropTypes.number.isRequired,
    toFixed: PropTypes.number.isRequired,
  }).isRequired,
  rate: PropTypes.number.isRequired,
  inMoney: PropTypes.bool.isRequired,
};

export default WalletMoney;
