import { createStore } from "redux";
import reducer from "@/reducers";
import config from "@/config";

const { buildNumber } = config;

const LOCALSTORAGE_NAMESPACE = `app-data-${buildNumber}-5`;

const persistedState = localStorage.getItem(LOCALSTORAGE_NAMESPACE)
  ? JSON.parse(localStorage.getItem(LOCALSTORAGE_NAMESPACE))
  : {};

/* eslint-disable no-underscore-dangle */
const store = createStore(
  reducer,
  persistedState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);
/* eslint-enable */

store.subscribe(() => {
  const { main, chat, auth } = store.getState();
  localStorage.setItem(
    LOCALSTORAGE_NAMESPACE,
    JSON.stringify({
      chat,
      main: {
        ...main,
        showMedkit: false,
        centrifugeToken: null,
        isGamePage: false,
      },
      auth: {
        ...auth,
        selectedItems: [],
        selectedItemsSum: 0,
      },
    }),
  );
});

export default store;
