import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const Layout = ({ children, header, footer, online, fullScreen, menu }) => {
  const { t } = useTranslation();

  const isChatActive = localStorage.getItem("chat-active");

  return (
    <>
      <div id="main-wrapper" className={`main-wrapper ${isChatActive ? "chat-active" : ""}`}>
        {header}
        <main className="games-wrapper pb-45">
          <div
            className={`container relative z-3 ${
              fullScreen ? "w-full transition-all duration-300" : ""
            }`}
          >
            <div className="sm:flex justify-between items-center sm:px-10">
              <nav className="secondary-nav">
                <ul>
                  {menu.map(({ id, onClick }, index) => {
                    return (
                      <li key={id}>
                        <button
                          type="button"
                          className={`secondary-nav__link ${index === 0 ? "active" : ""}`}
                          onClick={() => {
                            onClick();
                            return false;
                          }}
                        >
                          {t(`main.menu.${id}`)}
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </nav>

              {online}
            </div>
          </div>

          <div
            id="game-wrapper"
            className={`container transition-all duration-300 ${
              fullScreen ? "w-full" : ""
            } px-0 sm:px-container`}
          >
            {children}
          </div>
        </main>

        {footer}

        <div id="modal-portal" />
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.node.isRequired,
  footer: PropTypes.node.isRequired,
  online: PropTypes.node.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    }),
  ).isRequired,
};

export default Layout;
