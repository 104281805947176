import {
  SET_DICE_ROOMS,
  SET_DICE_ACTIVE_ROOM,
  SET_DICE_ACTIVE_ROOM_ROUND,
  SET_DICE_ACTIVE_ROOM_ROUND_BET,
  SET_DICE_USER_TO_ACTIVE_ROOM,
  SET_DICE_ROOM_USERS_COUNT,
  SET_DICE_LUCKY_WINS,
  ADD_DICE_LUCKY_WINS,
} from "@/constants";

const initialState = {
  rooms: [],
  luckyWins: [],
  activeRoom: {
    round: null,
  },
};

const dice = (state = { ...initialState }, action) => {
  switch (action.type) {
    case SET_DICE_ROOMS: {
      return {
        ...state,
        rooms: [...action.payload],
      };
    }
    case SET_DICE_ROOM_USERS_COUNT: {
      const { rooms } = state;
      const roomIndex = rooms.findIndex(({ id }) => action.payload.roomId === id);
      if (roomIndex > -1) {
        rooms[roomIndex] = {
          ...state.rooms[roomIndex],
          usersCount: action.payload.usersCount,
        };
      }
      return {
        ...state,
        rooms: [...state.rooms],
      };
    }
    case SET_DICE_ACTIVE_ROOM: {
      return {
        ...state,
        activeRoom: action.payload,
      };
    }
    case SET_DICE_ACTIVE_ROOM_ROUND: {
      return {
        ...state,
        activeRoom: {
          ...state.activeRoom,
          round: action.payload,
        },
      };
    }
    case SET_DICE_ACTIVE_ROOM_ROUND_BET: {
      const { bets } = state.activeRoom.round;

      const index = bets.findIndex(({ id }) => id === action.payload.id);

      if (index > -1) {
        bets[index] = action.payload;
      } else {
        bets.push(action.payload);
      }

      return {
        ...state,
        activeRoom: {
          ...state.activeRoom,
          round: {
            ...state.activeRoom.round,
            bets,
          },
        },
      };
    }
    case SET_DICE_USER_TO_ACTIVE_ROOM: {
      const { users } = state.activeRoom;

      users[action.payload.place] = action.payload.user;

      return {
        ...state,
        activeRoom: {
          ...state.activeRoom,
          users,
        },
      };
    }

    case SET_DICE_LUCKY_WINS: {
      return {
        ...state,
        luckyWins: [...action.payload],
      };
    }

    case ADD_DICE_LUCKY_WINS: {
      return {
        ...state,
        luckyWins: [action.payload, ...state.luckyWins].slice(0, 10),
      };
    }

    default:
      return state;
  }
};

export default dice;
