import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setOpen } from "@/actions/chatAction";
import ChatToogler from "@/components/chat/ChatToogler";
import mainWrapperClassManager from "@/helpers/mainWrapperClassManager";

const ChatToggleContainer = () => {
  const dispatch = useDispatch();
  const open = useSelector(({ chat }) => chat.open);

  useEffect(() => {
    const manager = mainWrapperClassManager("chat-active");
    if (open) {
      localStorage.setItem("chat-active", "1");
      manager.show();
    } else {
      localStorage.removeItem("chat-active");
      manager.hide();
    }
  }, [open]);

  return <ChatToogler onClick={() => dispatch(setOpen(!open))} />;
};

export default ChatToggleContainer;
