import {
  SET_COINFLIP_ROUNDS,
  SET_COINFLIP_ACTIVE_ROUND,
  SET_COINFLIP_BETS,
  NEW_COINFLIP_ROUND,
  NEW_COINFLIP_BET,
  REMOVE_COINFLIP_ROUND,
  UPDATE_COINFLIP_ROUND,
  SET_COINFLIP_POSITIONS,
} from "@/constants";

const initialState = {
  positions: [],
  rounds: [],
  bets: [],
  activeRound: null,
};

const coinflip = (state = { ...initialState }, action) => {
  const setRoundById = (id, data, withRound = false) => {
    const { rounds } = state;
    const index = rounds.findIndex(r => r.id === id);
    if (index > -1) {
      rounds[index] = {
        ...(withRound ? rounds[index] : { position: rounds[index].position }),
        ...data,
      };

      let { activeRound } = state;

      if (activeRound && activeRound.id === id) {
        activeRound = { ...activeRound, ...data };
      }

      return {
        ...state,
        activeRound,
        rounds: [...rounds],
      };
    }
    return state;
  };

  switch (action.type) {
    case SET_COINFLIP_POSITIONS: {
      return {
        ...state,
        positions: action.payload,
      };
    }
    case SET_COINFLIP_ROUNDS: {
      return {
        ...state,
        rounds: action.payload,
      };
    }
    case SET_COINFLIP_BETS: {
      return {
        ...state,
        bets: action.payload,
      };
    }
    case SET_COINFLIP_ACTIVE_ROUND: {
      return {
        ...state,
        activeRound: action.payload,
      };
    }
    case NEW_COINFLIP_ROUND: {
      const { rounds } = state;

      if (rounds.findIndex(round => round.id === action.payload.id) > -1) {
        return setRoundById(action.payload.id, action.payload, true);
      }

      let index = rounds.findIndex(round => !round.id);

      if (action.payload.isCreator) {
        index = 100 + rounds.filter(round => round.isCreator).length;
      }

      if (index === -1) {
        return state;
      }
      rounds[index] = {
        ...action.payload,
      };
      return {
        ...state,
        rounds,
      };
    }

    case NEW_COINFLIP_BET: {
      return {
        ...state,
        bets: [action.payload, ...state.bets].slice(0, 10),
      };
    }

    case UPDATE_COINFLIP_ROUND: {
      return setRoundById(action.payload.id, action.payload, true);
    }

    case REMOVE_COINFLIP_ROUND: {
      return setRoundById(action.payload.id, {});
    }

    default: {
      return state;
    }
  }
};

export default coinflip;
