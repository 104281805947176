import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useApi } from "@/api";
import noty from "@/libs/noty";
import { setUserTradeUrl } from "@/actions/authAction";
import WithdrawTradeLink from "@/components/withdraw/WithdrawTradeLink";

const WithdrawTradeLinkContainer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { tradeUrl, steamId } = useSelector(({ auth }) => auth.user);
  const [pending, setPending] = useState(false);
  const { updateTradeUrl } = useApi();

  const submit = url => {
    setPending(true);
    updateTradeUrl(url)
      .then(() => {
        setPending(false);
        dispatch(setUserTradeUrl(url));
        noty.success(t("withdraw.tradeUrl.success"));
      })
      .catch(error => {
        setPending(false);

        let message = t("withdraw.tradeUrl.fail");

        if (error.response) {
          if (error.response.status === 422) {
            message = t("withdraw.tradeUrl.wrongFormat");
          }

          if (error.response.status === 403) {
            message = t("withdraw.tradeUrl.accessForbidden");
          }
        }

        noty.error(message);
      });
  };

  return (
    <WithdrawTradeLink
      tradeUrl={tradeUrl}
      steamId={steamId}
      disabled={pending}
      onSubmit={submit}
    />
  );
};

export default WithdrawTradeLinkContainer;
