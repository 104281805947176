import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setInMoney, setCloseZero, updateUser } from "@/actions/authAction";
import Wallet from "@/components/Wallet";
import { useApi } from "@/api";

const WalletContainer = () => {
  const dispatch = useDispatch();

  const currencies = useSelector(({ main }) => main.currencies);
  const wallets = useSelector(({ auth }) => auth.user.wallets);
  const activeWallet = useSelector(({ auth }) => auth.user.activeWallet);
  const inMoney = useSelector(({ auth }) => auth.walletSettings.inMoney);
  const closeZero = useSelector(({ auth }) => auth.walletSettings.closeZero);

  const api = useApi();

  const setActiveWallet = wallet => {
    api.setActiveWallet(wallet.type).then(() => {
      dispatch(
        updateUser({
          activeWallet: wallet,
        }),
      );
    });
  };

  return (
    <Wallet
      currencies={currencies}
      userActiveWallet={activeWallet}
      userWallets={wallets}
      inMoney={inMoney}
      closeZero={closeZero}
      onChangeCloseZero={newCloseZero => dispatch(setCloseZero(newCloseZero))}
      onChangeInMoney={newInMoney => dispatch(setInMoney(newInMoney))}
      onSelectActiveWallet={setActiveWallet}
    />
  );
};

export default WalletContainer;
