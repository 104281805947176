import React from "react";
import PropTypes from "prop-types";
import { WALLET_BTC_TYPE, WALLET_USDT_TYPE, WALLET_ETH_TYPE } from "@/constants/walletTypes";

const map = {
  [WALLET_BTC_TYPE]: "BTC",
  [WALLET_ETH_TYPE]: "ETH",
  [WALLET_USDT_TYPE]: "$",
};

const WalletPrefix = ({ type }) => {
  return <>{map[type]}</>;
};

WalletPrefix.propTypes = {
  type: PropTypes.number.isRequired,
}

export default WalletPrefix;
