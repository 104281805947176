import PropTypes from "prop-types";

export default PropTypes.shape({
  id: PropTypes.number,
  deposit: PropTypes.number,
  player1: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
  player2: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
  status: PropTypes.number,
  waitingTime: PropTypes.number,
  winner: PropTypes.number,
});
