import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useApi } from "@/api";
import noty from "@/libs/noty";
import { updateWallet } from "@/actions/authAction";
import { WALLET_ETH_TYPE, WALLET_BTC_TYPE, WALLET_USDT_TYPE } from "@/constants/walletTypes";
import DepositCrypto from "@/components/deposit/DepositCrypto";

const DepositCryptoContainer = () => {
  const { t } = useTranslation();
  const wallets = useSelector(({ auth }) => auth.user.wallets);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { loadCryptoWallet } = useApi();

  const loadWallet = async walletType => {
    if (loading) {
      return false;
    }

    setLoading(true);

    return loadCryptoWallet({ type: walletType })
      .then(data => {
        dispatch(updateWallet(data));
        return true;
      })
      .catch(() => {
        noty.error(t("wallet.deposit.errorLoad"));
        return false;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <DepositCrypto
      availableWalletTypes={[WALLET_ETH_TYPE, WALLET_BTC_TYPE]}
      wallets={wallets.filter(wallet => wallet.address && wallet.type !== WALLET_USDT_TYPE)}
      onLoad={loadWallet}
      loading={loading}
    />
  );
};

export default DepositCryptoContainer;
