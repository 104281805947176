import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useApi } from "@/api";
import {
  setAutoCoefficientSettings,
  setAutoCoefficient,
  setBet,
  newBet,
  setLockManualBet,
} from "@/actions/crashAction";
import { setOpenAuth } from "@/actions/mainAction";
import noty from "@/libs/noty";
import useSound from "@/helpers/useSound";
import { BET_WAITING_STATUS, BET_WITHDRAW_STATUS } from "@/constants/crashBetStatuses";
import { CRASH_WAITING_STATUS, CRASH_RUNNING_STATUS } from "@/constants/crashStatuses";
import { WALLET_USDT_TYPE } from "@/constants/walletTypes";
import CrashControl from "@/components/games/crash/CrashControl";
import CrashUpgradeContainer from "@/containers/games/crash/CrashUpgradeContainer";

const CrashControlContainer = () => {
  const { t } = useTranslation();
  const sound = useSound();
  const dispatch = useDispatch();
  const autoCoefficientSettings = useSelector(({ crash }) => crash.autoCoefficientSettings);
  const autoCoefficient = useSelector(({ crash }) => crash.autoCoefficient);
  const bet = useSelector(({ crash }) => crash.bet);
  const status = useSelector(({ crash }) => crash.status);
  const user = useSelector(({ auth }) => auth.user);
  const inMoney = useSelector(({ auth }) => auth.walletSettings.inMoney);
  const currencies = useSelector(({ main }) => main.currencies);

  const [pendingBet, setPendingBet] = useState(null);

  const { crashMake } = useApi();

  useEffect(() => {
    dispatch(setLockManualBet(false));
  });

  const create = data => {
    let { deposit } = data;
    const { autoCoefficient } = data;

    if (!user) {
      dispatch(setOpenAuth(true));
      return;
    }

    sound.bet();

    if (Number(deposit) === 0) {
      const demoBet = {
        id: 0,
        status: BET_WAITING_STATUS,
        coefficientAuto: autoCoefficient,
        deposit: 0,
        walletType: WALLET_USDT_TYPE,
        user: {
          id: user.id,
          name: user.name,
        },
      };

      dispatch(setBet(demoBet));
      dispatch(newBet(demoBet));
      return;
    }

    if (inMoney) {
      deposit /= currencies[user.activeWallet.type];
    }

    if (Number(deposit) > user.activeWallet.balance) {
      noty.error(t("notEnoughBalance"));
      return;
    }

    crashMake({ deposit, autoCoefficient, inMoney })
      .then(() => {
        noty.success(t("crash.create.success"));
      })
      .catch(() => {
        noty.error(t("crash.create.error"));
      });
  };

  const makeBet = data => {
    if (status !== CRASH_WAITING_STATUS) {
      setPendingBet(data);
      return;
    }

    create(data);
  };

  const clearPendingBet = () => {
    setPendingBet(null);
  };

  useEffect(() => {
    if (status === CRASH_WAITING_STATUS && pendingBet) {
      create(pendingBet);
      clearPendingBet();
    }
  }, [status, pendingBet]);

  const walletType = user && user.activeWallet ? user.activeWallet.type : WALLET_USDT_TYPE;

  return (
    <CrashControl
      autoCoefficientSettings={autoCoefficientSettings}
      onChangeAutoCoefficientSettings={newAutoCoefficientSettings =>
        dispatch(setAutoCoefficientSettings(newAutoCoefficientSettings))
      }
      defaultAutoCoefficient={autoCoefficient}
      onChangeAutoCoefficient={newAutoCoefficient => setAutoCoefficient(newAutoCoefficient)}
      onCreate={makeBet}
      disabledInput={(bet && bet.status === BET_WAITING_STATUS) || !!pendingBet}
      havePendingBet={!!pendingBet}
      onClearPendingBet={clearPendingBet}
      isUpgrade={!!bet && bet.status !== BET_WITHDRAW_STATUS && status === CRASH_RUNNING_STATUS}
      upgrade={<CrashUpgradeContainer />}
      isStarting={!!bet && status === CRASH_WAITING_STATUS}
      walletType={inMoney ? WALLET_USDT_TYPE : walletType}
      inMoney={inMoney}
    />
  );
};

export default CrashControlContainer;
