import React, { useRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CRASH_WAITING_STATUS,
  CRASH_RUNNING_STATUS,
  CRASH_SHIPPED_STATUS,
} from "@/constants/crashStatuses";
import useSound from "@/helpers/useSound";
import Progress from "./Progress";
import propTypes from "./propTypes";

let drops = [];

const Chart = ({ status, delta, crash, bets, onChangeCoefficient, isResize, isDemo }) => {
  const { t } = useTranslation();
  const wrapRef = useRef();
  const sound = useSound();
  const [toggle, setToggle] = useState(false);
  const [game, setGame] = useState();

  useEffect(() => {
    if (!game) {
      setTimeout(() => {
        setGame(
          new Progress(wrapRef.current, onChangeCoefficient, () => {
            //sound.prepareGrow()
          }),
        );
        setToggle(!toggle);
      }, 200);
    }

    return () => {
      if (game) {
        game.stop();
      }
    };
  }, [wrapRef, game]);

  useEffect(() => {
    if (game) {
      setTimeout(() => {
        game.setSvgParams();
      }, 200);
    }
  }, [game, isResize]);

  useEffect(() => {
    if (!game) {
      return;
    }
    switch (status) {
      case CRASH_WAITING_STATUS: {
        if (!game.isCountDown) {
          game.countDown((delta < -10 ? 10 : delta) * 1000);
          drops = [];
        }
        break;
      }
      case CRASH_RUNNING_STATUS: {
        if (!game.started) {
          game.start(delta > 1 ? delta * 1000 : 0);
        } else {
          game.updateProgress(delta);
        }
        break;
      }
      case CRASH_SHIPPED_STATUS: {
        if (crash) {
          game.start(delta * 1000, false);
          game.stopped = true;
          game.step(false);
          game.finish(crash);
        }
        break;
      }
      default:
        break;
    }
  }, [status, delta, crash, toggle]);

  useEffect(() => {
    if (!game) {
      return;
    }

    if (game.started) {
      bets.forEach(({ id, withdraw, coefficient }) => {
        if (drops.findIndex(dropId => dropId === id) === -1) {
          game.addDrop(id === 0 ? "demo" : withdraw, coefficient.toFixed(2));
          drops.push(id);
        }
      });
    }
  }, [bets, drops]);

  return (
    <div className="graph-svg countdown" ref={wrapRef}>
      <div className="graph-svg__countdown">
        <div className="graph-svg__countdown-title">{t("crash.countdown.title")}</div>
        {status === CRASH_WAITING_STATUS && (
          <div className="graph-svg__countdown-desc">{t("crash.countdown.desc")}</div>
        )}

        <div className="w-200 sm:w-300 mx-auto mt-10 text-left">
          <div className={`text-2xl text-white mb-10 h-18 ${status !== CRASH_WAITING_STATUS ? "hidden" : ""}`} data-graph-countdown-value />
          <div className="h-2 bg-primary-100 bg-opacity-10 w-full">
            <div
              className="h-full bg-primary relative"
              style={{ width: "100%" }}
              data-graph-countdown-progress
            >
              <div className="h-6 absolute top-1/2 right-0 transform -translate-y-1/2 w-2 bg-inherit" />
            </div>
          </div>
        </div>
      </div>

      {isDemo && (
        <div className="graph-svg-demo">
          <div className="graph-svg-demo__title">{t("crash.demo.title")}</div>
          <div className="graph-svg-demo__desc">{t("crash.demo.desc")}</div>
        </div>
      )}

      <div className="graph-svg__counter">
        <div
          className={`graph-svg__counter-title ${
            status === CRASH_SHIPPED_STATUS ? "text-red" : ""
          }`}
          data-graph-counter-value
        />

        <div className="graph-svg__counter-desc">
          {t(`crash.${status === CRASH_SHIPPED_STATUS ? "finish" : "progress"}.desc`)}
        </div>
      </div>

      <div className="graph-svg__lines" data-graph-lines />
      <div className="graph-svg__seconds" data-graph-seconds />
      <div className="graph-svg__main-wrapper">
        <div className="graph-svg__drops" data-graph-drops />
        <svg
          className="graph-svg__main"
          id="graph-svg-main"
          data-graph-main
          xmlns="http://www.w3.org/2000/svg"
        >
          <path data-graph-line-before stroke="currentColor" opacity=".1" fill="none" />
          <path data-graph-line stroke="currentColor" fill="none" />
          <path data-graph-line-after stroke="currentColor" opacity=".1" fill="none" />
        </svg>
      </div>
    </div>
  );
};

Chart.propTypes = propTypes;

export default Chart;
