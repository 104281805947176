import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Bets from "@/components/games/Bets";

const TowerBets = ({ bets }) => {
  const { t } = useTranslation();

  return (
    <div className="pt-25 -mx-20 overflow-hidden flex flex-col mt-8 max-h-300">
      <div className="flex items-center text-gray-600 mb-10 px-20">{t("towers.lastGames")}</div>

      <Bets bets={bets} />
    </div>
  );
};

TowerBets.propTypes = {
  bets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      coefficient: PropTypes.number.isRequired,
      deposit: PropTypes.number.isRequired,
      withdraw: PropTypes.number.isRequired,
      status: PropTypes.number.isRequired,
      userName: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default TowerBets;
